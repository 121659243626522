import { useEffect } from "react";
import { changeHeaderOptions, useAppDispatch } from "store";
import {
  Layout,
  Section,
  TextContentBlock,
  TextContentBlockParagraph,
  Title,
} from "components";

const Delivery = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: true,
        showBackButton: false,
        showMainPageButton: false,
        showSearch: true,
      })
    );
  }, [dispatch]);

  return (
    <Layout>
      <Section className="page-section">
        <Title Tag="h1" text="Доставка товаров" />

        <TextContentBlock>
          <TextContentBlockParagraph>
            Доставка осуществляется через постаматы и пункты выдачи PickPoint.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            PickPoint – первый логистический сервис нового поколения,
            доставляющий онлайн-заказы через постаматы и пункты выдачи.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Постамат – это автоматизированный оранжевый терминал, который
            установлен рядом с вашим домом, работой или учебой, в магазине,
            супермаркете, торговом центре.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Онлайн-заказ в постамате вы получаете самостоятельно в удобное для
            вас время.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Для этого в интерфейсе постамата нужно указать код получения заказа,
            который поступит к вам на мобильный телефон или e-mail.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Если вы не захотели оплачивать свою покупку на сайте
            интернет-магазина, вы можете это сделать в постамате наличными или
            банковской картой.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Не подошедший товар вы можете вернуть через любой постамат
            PickPoint. При этом не важно, каким способом он был получен: в
            почтовом отделении, через курьера, в постамате или в пункте выдачи.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Милые девочки такие девочки, которые нуждаются в особом отношении к
            себе и к своей природной красоте.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Мы готовы дарить Вам прекрасные эмоции каждый день и желаем всегда
            заботиться в первую очередь о себе
          </TextContentBlockParagraph>
        </TextContentBlock>
      </Section>
    </Layout>
  );
};

export default Delivery;
