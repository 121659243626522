import { useCartItems } from "hooks";
import CartProductItem from "./CartProductItem";

import css from "./CartProductList.module.scss";

const CartProductList = () => {
  const { cartProducts, isCartEmpty, isLoadingCartProducts, totalSum } =
    useCartItems();

  return (
    <>
      {isCartEmpty ? (
        <p className={css.not_products}>Вы еще не выбрали ни один товар!</p>
      ) : (
        <>
          <ul className={css.cart_products}>
            {cartProducts.map((cartItem) => (
              <CartProductItem
                key={cartItem.id + cartItem.title}
                {...cartItem}
                disabled={isLoadingCartProducts}
              />
            ))}
          </ul>

          <p className={css.cart_amount}>
            <span className={css.small}>Итого: </span>
            <span className={css.big}>{totalSum}</span>
            <span className={css.small}> руб</span>
          </p>
        </>
      )}
    </>
  );
};

export default CartProductList;
