import { FC } from "react";
import {
  CategoryFilters,
  Layout,
  Pagination,
  ProductsList,
  Section,
  Spinner,
} from "components";
import { SORT_DIRECTION, SORT_VARIANTS } from "settings";
import { TCommonComponent, TProduct, TSortDirection } from "types";
import { getMaxPageNumber } from "utils";

type TCommonCategoryPartProps = TCommonComponent & {
  products: TProduct[];
  countItems: number;
  currentPage: number;
  isProductsLoading?: boolean;
  isLoading?: boolean;
  sortVariant: SORT_VARIANTS;
  sortDirection: TSortDirection;

  changePageCb: (value: React.SetStateAction<number>) => void;
  changeSortDirectionCb: (value: React.SetStateAction<TSortDirection>) => void;
  changeSortVariantCb: (value: React.SetStateAction<SORT_VARIANTS>) => void;
};

const CommonCategoryPart: FC<TCommonCategoryPartProps> = ({
  countItems,
  products,
  children,
  currentPage,
  isProductsLoading = false,
  isLoading = false,
  sortVariant,
  sortDirection,

  changePageCb,
  changeSortDirectionCb,
  changeSortVariantCb,
}) => {
  const maxPage = getMaxPageNumber(countItems);

  const changePage = (numberPage: number) => {
    changePageCb(numberPage);
  };

  const changeSortOptions = (
    variant: SORT_VARIANTS,
    direction: TSortDirection
  ) => {
    changePageCb(1);

    let anotherDirection: TSortDirection = SORT_DIRECTION.ASC;

    if (variant === sortVariant) {
      anotherDirection =
        direction === SORT_DIRECTION.ASC
          ? SORT_DIRECTION.DESC
          : SORT_DIRECTION.ASC;
      changeSortDirectionCb(anotherDirection);
      return;
    }

    changeSortVariantCb(variant);
    changeSortDirectionCb(anotherDirection);
  };

  return (
    <Layout>
      <Section
        className="category"
        insertBeforeWrapper={
          <CategoryFilters
            changeSortOptions={changeSortOptions}
            sortVariant={sortVariant}
            sortDirection={sortDirection}
            disabledButtons={isLoading}
          />
        }
      >
        {children}

        {isProductsLoading ? (
          <Spinner />
        ) : (
          <ProductsList listElements={products} />
        )}

        <Pagination
          changePage={changePage}
          stopClick={isLoading}
          currentPage={currentPage}
          maxPage={maxPage}
        />
      </Section>
    </Layout>
  );
};

export default CommonCategoryPart;
