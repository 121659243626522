import { FC } from "react";
import { getPhotoOrDefaultImgLink } from "utils";
import { TProductPhotoInfo } from "types";
import css from "./TextContentBlock.module.scss";

const TextContentBlockPhoto: FC<TProductPhotoInfo> = ({
  url,
  alt = "Фотография",
}) => {
  const photoUrl = getPhotoOrDefaultImgLink(process.env.PUBLIC_URL + url);

  return (
    <button
      data-fancybox="gallery"
      data-src={photoUrl}
      className="text-content__photo"
    >
      <img src={photoUrl} alt={alt} />
    </button>
  );
};

export default TextContentBlockPhoto;
