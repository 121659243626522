import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetHomeSliderProductsQuery } from "service";
import { getPhotoOrDefaultImgLink, getProductUrlLink } from "utils";
import { SwiperSlide } from "swiper/react";
import { Slider, Spinner } from "components";
import css from "./Home.module.scss";

const TIME_SLIDER_SWIPE = 30000;

const MainSlider = () => {
  const {
    currentData: homeSliderProducts = [],
    // isError: isErrorHomeSliderProductsAdd,
    // isSuccess: isSuccessHomeSliderProductsAdd,
    // error: homeSliderProductsAddErrorData,
    isLoading: isHomeSliderProductsAddLoading,
  } = useGetHomeSliderProductsQuery(null);

  return (
    <>
      {isHomeSliderProductsAddLoading ? (
        <Spinner />
      ) : (
        <Slider
          className={css.main_slider}
          needNavigation={true}
          needPagination={true}
          timeAutoPlay={TIME_SLIDER_SWIPE}
          autoplay={false}
        >
          {homeSliderProducts.map(
            ({ slug, category, images, title, variants }, index) => {
              const urlLink = getProductUrlLink(slug, category.slug);

              let startPhoto = null;
              if (images.length) {
                startPhoto = images[0].thumbnail;
              } else if (variants.length) {
                let s = 0;
                while (s < variants.length) {
                  if (variants[s].thumbnail) {
                    startPhoto = variants[s].thumbnail;
                    break;
                  }
                  s++;
                }
              }

              const imageSrc = getPhotoOrDefaultImgLink(startPhoto);

              return (
                <SwiperSlide tag="li" key={index}>
                  <Link to={urlLink} className={css.slider_item_link}>
                    <img
                      className={css.slider_item_img}
                      src={imageSrc}
                      alt={title}
                    />
                  </Link>
                </SwiperSlide>
              );
            }
          )}
        </Slider>
      )}
    </>
  );
};

export default MainSlider;
