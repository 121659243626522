import { FC, ReactNode, useEffect } from "react";
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

type TFancy = {
  delegate?: string;
  options?: {
    startIndex?: number;
    preload?: number;
    infinite?: boolean;
    showClass?: string;
    hideClass?: string;
    animated?: boolean;
    hideScrollbar?: boolean;
    parentEl?: null | string;
    mainClass?: null | string;
    autoFocus?: boolean;
    trapFocus?: boolean;
    placeFocusBack?: boolean;
    click?: string;
    closeButton?: string;
    dragToClose?: boolean;
    l10n?: "en" | "ru";
  };
  children: ReactNode;
};

const Fancybox: FC<TFancy> = ({
  delegate = "[data-fancybox]",
  options = {},
  children,
}) => {
  useEffect(() => {
    NativeFancybox.bind(delegate, options);

    return () => {
      NativeFancybox.destroy();
    };
  }, [delegate, options]);

  return <>{children}</>;
};

export default Fancybox;
