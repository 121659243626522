import { FC, ReactNode } from "react";

type TTextContentBlockParagraph = {
  children: ReactNode;
};

const TextContentBlockParagraph: FC<TTextContentBlockParagraph> = ({
  children,
}) => <p>{children}</p>;

export default TextContentBlockParagraph;
