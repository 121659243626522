import { FC } from "react";
import { Link } from "react-router-dom";

type TNav = {
  classNameNav: string;
  classNameList: string;
  classNameItem: string;
  classNameLink: string;
  imagesIcons: boolean;
  links: Array<{ id: string; text: string; link: string; mobileImg?: string }>;
};

const Nav: FC<TNav> = ({
  imagesIcons = "",
  classNameNav,
  classNameList,
  classNameItem,
  classNameLink,
  links,
}) => {
  if (!links.length) return null;

  return (
    <nav className={classNameNav}>
      <ul className={classNameList}>
        {links.map(({ link, text, id, mobileImg }) => {
          return (
            <li className={classNameItem} key={id}>
              <Link className={classNameLink} to={link}>
                {imagesIcons ? (
                  <span
                    style={{
                      backgroundImage: `url(${mobileImg})`,
                    }}
                  ></span>
                ) : null}

                {text}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
