import { FC, useEffect, useState } from "react";
import PaginationItem from "./PaginationItem";
import { checkValue } from "utils";
import { MIN_PAGINATION_PAGE } from "settings";
import { TChangeElHandler, TSendForm } from "types";
import css from "./Pagination.module.scss";

type TPaginationPage = {
  changePage: (page: number) => void;
  stopClick: boolean;
  maxPage: number;
  currentPage: number;
};

const PaginationPage: FC<TPaginationPage> = ({
  changePage,
  maxPage,
  currentPage,
  stopClick,
}) => {
  const [inputValue, setInputValue] = useState<number>(1);

  if (maxPage <= MIN_PAGINATION_PAGE) return null;

  /* create paginations arr start */
  const paginationsArr = [];
  // start
  checkValue(currentPage - 3, MIN_PAGINATION_PAGE, "more") &&
    paginationsArr.push({ content: "<<", number: 1 });
  // -2
  checkValue(currentPage - 2, MIN_PAGINATION_PAGE, "more") &&
    paginationsArr.push({ content: currentPage - 2, number: currentPage - 2 });
  // -1
  checkValue(currentPage - 1, MIN_PAGINATION_PAGE, "more") &&
    paginationsArr.push({ content: currentPage - 1, number: currentPage - 1 });
  // active
  paginationsArr.push({ content: currentPage, number: currentPage });
  // +1
  checkValue(currentPage + 1, maxPage, "less") &&
    paginationsArr.push({ content: currentPage + 1, number: currentPage + 1 });
  // +2
  checkValue(currentPage + 2, maxPage, "less") &&
    paginationsArr.push({ content: currentPage + 2, number: currentPage + 2 });
  // end
  checkValue(currentPage + 3, maxPage, "less") &&
    paginationsArr.push({ content: ">>", number: maxPage });
  /* create paginations arr end */

  const clickPaginationButton = (id: number) => {
    if (id === currentPage) return;
    setInputValue(id);
    changePage(id);
  };

  const changeInputHandler: TChangeElHandler<HTMLInputElement> = (event) => {
    const newCurrent = Number.parseFloat(event.target.value) || 1;
    setInputValue(newCurrent);
  };

  const submitHandler: TSendForm = (event) => {
    event.preventDefault();

    let page = inputValue;
    if (inputValue > maxPage) {
      page = maxPage;
      setInputValue(maxPage);
    }
    if (inputValue < MIN_PAGINATION_PAGE) {
      page = MIN_PAGINATION_PAGE;
      setInputValue(MIN_PAGINATION_PAGE);
    }
    if (currentPage === page) return;

    changePage(page);
  };

  return (
    <ul className={css.pagination_list}>
      {paginationsArr.map(({ content, number }) => (
        <PaginationItem
          key={content}
          currentPage={currentPage}
          currentInput={inputValue}
          content={content}
          number={number}
          stopClick={stopClick}
          clickPaginationButton={clickPaginationButton}
          changeInputHandler={changeInputHandler}
          submitHandler={submitHandler}
        />
      ))}
    </ul>
  );
};

export default PaginationPage;
