import { FC, memo } from "react";
import classNames from "classnames";
import { Badge } from "components";
import { TProductBadge } from "types/product";
import css from "./ProductBadges.module.scss";

type TProductBadgesProps = {
  badges: TProductBadge[];
  classNameWrapper?: string;
  classNameItem?: string;
  Tag?: "span" | "p";
};

const ProductBadges: FC<TProductBadgesProps> = ({
  badges,
  Tag = "p",
  classNameWrapper = "",
  classNameItem = "",
}) => {
  if (!badges.length) return null;

  return (
    <Tag
      className={classNames(css.product_badges, {
        [classNameWrapper]: classNameWrapper,
      })}
    >
      {badges.map((badgeInfo) => (
        <Badge
          classNameItem={classNameItem}
          key={badgeInfo.title}
          {...badgeInfo}
        />
      ))}
    </Tag>
  );
};

export default memo(ProductBadges);
