import { useEffect } from "react";
import { useGetHomeProductsQuery } from "service";
import { changeHeaderOptions, useAppDispatch } from "store";
import { Layout, Nav, ProductsList, Section, Spinner, Title } from "components";
import MainSlider from "./MainSlider";
import { HEADER_DOWN_NAV } from "settings";
import css from "./Home.module.scss";

const Home = () => {
  const dispatch = useAppDispatch();

  const {
    currentData: homeProducts = [],
    // isError: isErrorHomeProductsAdd,
    // isSuccess: isSuccessHomeProductsAdd,
    // error: homeProductsAddErrorData,
    isLoading: isHomeProductsAddLoading,
  } = useGetHomeProductsQuery(null);

  useEffect(() => {}, [isHomeProductsAddLoading]);

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: true,
        showBackButton: false,
        showMainPageButton: false,
        showSearch: true,
      })
    );
  }, [dispatch]);

  return (
    <Layout>
      <Section className={css.top} classNameWrapper={css.wrapper}>
        <Nav
          classNameNav={css.nav_home}
          classNameList={css.nav_home__list}
          classNameItem={css.nav_home__list_item}
          classNameLink={css.anchor}
          imagesIcons={true}
          links={HEADER_DOWN_NAV}
        />

        <Title
          Tag="h1"
          className={css.top__title}
          text="Интернет магазин для телеграмма Ancy.by"
        />

        <MainSlider />

        {isHomeProductsAddLoading ? (
          <Spinner />
        ) : (
          <ProductsList listElements={homeProducts} />
        )}
      </Section>
    </Layout>
  );
};

export default Home;
