import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  API_TAGS,
  COUNT_LEADERS_PRODUCTS,
  COUNT_NEW_PRODUCTS,
  COUNT_SALE_PRODUCTS,
  SERVICE_API_URL,
  SORT_VARIANTS,
} from "settings";
import { changeResponseProductsData } from "utils";
import { TProduct, TProductsResponse, TSortDirection } from "types";

const endpoints = {
  oneProduct: "",
  itemsFromArray: "items/",
  homeSliderItems: "home_slider/",
  homePageItems: "home/",
  saleItems: "sale/",
  leadersItems: "leaders/",
  newItems: "new/",
  searchItems: "search/",
};

type TSaleNewLeaderBody = {
  sortVariant: SORT_VARIANTS;
  sortDirection: TSortDirection;
};

type TSearchBodyRequest = TSaleNewLeaderBody & {
  limit?: number;
  offset?: number;
  search: string;
};

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVICE_API_URL}products/`,
  }),
  tagTypes: [API_TAGS.products],
  endpoints: (builder) => ({
    getHomeSliderProducts: builder.query<TProduct[], null>({
      query: () => ({
        url: endpoints.homeSliderItems,
      }),
      transformResponse: changeResponseProductsData,
    }),

    getHomeProducts: builder.query<TProduct[], null>({
      query: () => ({
        url: endpoints.homePageItems,
      }),
      transformResponse: changeResponseProductsData,
    }),

    getProductsFromIds: builder.query<TProduct[], string[]>({
      query: (listIds) => {
        let searchParams = '';
        if (listIds && listIds.length) {
          searchParams = "?q=" + listIds.join(",");
        }
        return {
          url: endpoints.itemsFromArray + searchParams,
        };
      },
    }),

    getOneProduct: builder.query<TProduct, { slug: string }>({
      query: ({ slug }) => ({
        url: endpoints.oneProduct + slug + '/',
      }),
    }),

    getLeadersProducts: builder.query<TProductsResponse, TSaleNewLeaderBody>({
      query: ({ sortVariant, sortDirection }) => {
        return {
          url: `${endpoints.leadersItems}?limit=${COUNT_LEADERS_PRODUCTS}&offset=0&variant=${sortVariant}&direction=${sortDirection}`,
        };
      },
    }),

    getNewProducts: builder.query<TProductsResponse, TSaleNewLeaderBody>({
      query: ({ sortVariant, sortDirection }) => {
        return {
          url: `${endpoints.newItems}?limit=${COUNT_NEW_PRODUCTS}&offset=0&variant=${sortVariant}&direction=${sortDirection}`,
        };
      },
    }),

    getSaleProducts: builder.query<TProductsResponse, TSaleNewLeaderBody>({
      query: ({ sortVariant, sortDirection }) => {
        return {
          url: `${endpoints.saleItems}?limit=${COUNT_SALE_PRODUCTS}&offset=0&variant=${sortVariant}&direction=${sortDirection}`,
        };
      },
    }),

    getSearchProducts: builder.query<TProductsResponse, TSearchBodyRequest>({
      query: ({ search, sortDirection, sortVariant, limit, offset }) => {
        return {
          url: `${endpoints.searchItems}?search=${search}&limit=${limit}&offset=${offset}&variant=${sortVariant}&direction=${sortDirection}`,
        };
      },
    }),
  }),
});

export const {
  useGetOneProductQuery,
  useGetProductsFromIdsQuery,
  useGetHomeSliderProductsQuery,
  useGetHomeProductsQuery,
  useGetLeadersProductsQuery,
  useGetNewProductsQuery,
  useGetSaleProductsQuery,
  useGetSearchProductsQuery,
} = productsApi;
