import { FC } from "react";
import {
  Fancybox,
  ProductPhotoOrSlider,
  ProductPrice,
  ProductCountBtns,
  ProductBadges,
} from "components";
import ProductTabs from "./ProductTabs";
import ProductAttributesText from "./ProductAttributesText";
import ProductDescription from "./ProductDescription";
import { getProductPhotosData } from "utils";
import { TProduct } from "types";

import css from "./Product.module.scss";

const ProductSimple: FC<TProduct> = ({
  id,
  title,
  badge,
  brand,
  category,
  description,
  images,
  price,
  sale_price,
  colors,
  series,
  size,
  structure,
  quantity,

  sales,
  height_side,
  long_side,
  square,
  weight,
  weight_side,
}) => {
  const photosForSlider = getProductPhotosData(title, images, []);

  const tabsContent = [
    {
      buttonTitle: "Описание",
      content: <ProductDescription description={description} />,
    },
    {
      buttonTitle: "Характеристики",
      content: (
        <ProductAttributesText
          brand={brand}
          category={category}
          colors={colors}
          structure={structure}
          series={series}
          size={size}
        />
      ),
    },
  ];

  return (
    <>
      <Fancybox>
        <ProductPhotoOrSlider
          images={photosForSlider}
          needNavigation={true}
          needPagination={true}
          needButtonWrapper={true}
          itemClassName={css.product__photo}
          sliderClassName={css.product__slider}
          slideChangeTime={10000}
        />
      </Fancybox>

      <ProductBadges
        classNameWrapper={css.product__badges}
        classNameItem={css.badge__item}
        badges={badge}
        Tag="p"
      />

      <div className={css.product__sale_info}>
        <ProductPrice
          basePrice={price}
          salePrice={sale_price}
          className={css.product__price}
          Tag="p"
        />

        {/* {(Boolean(sales) || quantity) && (
          <div className={css.product__sale_info_item}>
            {Boolean(sales) && <p>Продано {sales}</p>}
            {quantity && <p>Осталось {quantity}</p>}
          </div>
        )} */}
      </div>

      <ProductCountBtns
        idParent={null}
        id={id}
        quantity={quantity}
        className={css.product__buttons}
      />

      <ProductTabs tabsData={tabsContent} />
    </>
  );
};

export default ProductSimple;
