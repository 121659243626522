import { FC, TdHTMLAttributes, ThHTMLAttributes } from "react";
import classNames from "classnames";
import { TCommonComponent } from "types";
import css from "./TableItem.module.scss";

type TTableItem = TCommonComponent &
  TdHTMLAttributes<HTMLTableCellElement> &
  ThHTMLAttributes<HTMLTableCellElement> & {
    Tag: "td" | "th";
  };

const TableItem: FC<TTableItem> = ({
  Tag,
  className = "",
  children,
  ...last
}) => (
  <Tag
    className={classNames(css.table_item, {
      [className]: className,
    })}
    {...last}
  >
    {children}
  </Tag>
);

export default TableItem;
