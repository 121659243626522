import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { changeHeaderOptions, useAppDispatch } from "store";
import { useGetSearchProductsQuery } from "service";
import { CommonCategoryPart, Title } from "components";
import {
  COUNT_PRODUCTS_PER_PAGE,
  SORT_DIRECTION,
  SORT_VARIANTS,
} from "settings";
import { TSortDirection } from "types";

const SearchPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [sortVariant, setSortVariant] = useState<SORT_VARIANTS>(
    SORT_VARIANTS.name
  );
  const [sortDirection, setSortDirection] = useState<TSortDirection>(
    SORT_DIRECTION.ASC
  );

  const {
    currentData: searchProducts = { results: [], count: 0 },
    // isError: isErrorCategoryInfo,
    // isSuccess: isSuccessCategoryInfo,
    // error: categoryInfoErrorData,
    isLoading: isSearchProductsLoading,
  } = useGetSearchProductsQuery({
    search: searchValue,
    limit: COUNT_PRODUCTS_PER_PAGE,
    offset: COUNT_PRODUCTS_PER_PAGE * (currentPage - 1),
    sortVariant: sortVariant,
    sortDirection: sortDirection,
  });

  useEffect(() => {
    setSearchValue(searchParams.get("q") || "");
    setCurrentPage(1);
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: false,
        showBackButton: true,
        showMainPageButton: false,
        showSearch: true,
      })
    );
  }, [dispatch]);

  return (
    <CommonCategoryPart
      countItems={searchProducts.count}
      currentPage={currentPage}
      sortVariant={sortVariant}
      sortDirection={sortDirection}
      products={searchProducts.results}
      isLoading={isSearchProductsLoading}
      isProductsLoading={isSearchProductsLoading}
      changePageCb={setCurrentPage}
      changeSortVariantCb={setSortVariant}
      changeSortDirectionCb={setSortDirection}
    >
      <Title Tag="h1" text={`Результат поиска по: ${searchValue}`} />
    </CommonCategoryPart>
  );
};

export default SearchPage;
