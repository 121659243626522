import { FC } from "react";
import classNames from "classnames";
import { Button } from "components";
import css from "./Product.module.scss";
import { getPhotoOrDefaultImgLink } from "utils";

type TVariationButtonProps = {
  id: number;
  chosenProductId: number | null;
  thumbnail: null | string;
  name: string;
  changeChosenProductId: (id: number) => void;
};

const VariationButton: FC<TVariationButtonProps> = ({
  id,
  chosenProductId,
  changeChosenProductId,
  thumbnail,
  name,
}) => {
  let photo: string | null = null;
  if (thumbnail) {
    photo = getPhotoOrDefaultImgLink(thumbnail);
  }

  return (
    <Button
      className={classNames(css.variations_button, {
        [css.active]: id === chosenProductId,
      })}
      onClick={() => changeChosenProductId(id)}
    >
      {photo ? (
        <img
          className={css.variations_button_img}
          src={process.env.PUBLIC_URL + photo}
          alt={name}
        />
      ) : (
        <span className={css.variations_button_text}>{name}</span>
      )}
    </Button>
  );
};

export default VariationButton;
