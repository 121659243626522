import { FC } from "react";
import ProductsItem from "./ProductsItem";
import { TProduct } from "types";
import css from "./ProductsList.module.scss";

type TProductsList = {
  listElements: TProduct[];
};

const ProductsList: FC<TProductsList> = ({ listElements }) => {
  if (listElements.length === 0) return null;

  return (
    <ul className={css.catalog__list}>
      {listElements.map((oneProd) => {
        return <ProductsItem key={oneProd.id} {...oneProd} />;
      })}
    </ul>
  );
};

export default ProductsList;
