import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { changeHeaderOptions, useAppDispatch } from "store";
import { useGetCategoryInfoQuery, useGetCategoryProductsQuery } from "service";
import {
  CommonCategoryPart,
  Spinner,
  TextContentBlock,
  Title,
} from "components";
import {
  COUNT_PRODUCTS_PER_PAGE,
  SORT_DIRECTION,
  SORT_VARIANTS,
} from "settings";
import { TCategory, TSortDirection } from "types";

const Category = () => {
  const dispatch = useAppDispatch();
  const { category = "" } = useParams();
  const [sortVariant, setSortVariant] = useState<SORT_VARIANTS>(
    SORT_VARIANTS.name
  );
  const [sortDirection, setSortDirection] = useState<TSortDirection>(
    SORT_DIRECTION.ASC
  );
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    currentData: categoryInfo = {} as TCategory,
    // isError: isErrorCategoryInfo,
    // isSuccess: isSuccessCategoryInfo,
    // error: categoryInfoErrorData,
    isLoading: isCategoryInfoLoading,
  } = useGetCategoryInfoQuery(category);

  const {
    currentData: prodCategory = {
      count: 0,
      results: [],
    },
    // isError: isErrorProdCategory,
    // isSuccess: isSuccessProdCategory,
    // error: prodCategoryErrorData,
    isLoading: isProdCategoryLoading,
  } = useGetCategoryProductsQuery({
    categoryName: category || "",
    limit: COUNT_PRODUCTS_PER_PAGE,
    offset: COUNT_PRODUCTS_PER_PAGE * (currentPage - 1),
    sortVariant: sortVariant,
    sortDirection: sortDirection,
  });

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: false,
        showBackButton: true,
        showMainPageButton: false,
        showSearch: true,
      })
    );
  }, [dispatch]);

  return (
    <CommonCategoryPart
      countItems={prodCategory.count}
      currentPage={currentPage}
      sortVariant={sortVariant}
      sortDirection={sortDirection}
      products={prodCategory.results}
      isLoading={isCategoryInfoLoading || isProdCategoryLoading}
      isProductsLoading={isProdCategoryLoading}
      changePageCb={setCurrentPage}
      changeSortVariantCb={setSortVariant}
      changeSortDirectionCb={setSortDirection}
    >
      {isCategoryInfoLoading ? (
        <Spinner />
      ) : (
        <>
          {categoryInfo.title && <Title Tag="h1" text={categoryInfo.title} />}
          {currentPage === 1 && categoryInfo.description && (
            <TextContentBlock
              className="text-content"
              dangerouslySetInnerHTML={{ __html: categoryInfo.description }}
            />
          )}
        </>
      )}
    </CommonCategoryPart>
  );
};

export default Category;
