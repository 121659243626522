import { FC, ReactNode } from "react";
import Wrapper from "../Wrapper";
import { TCommonComponent } from "types";

type TSection = TCommonComponent &
  React.HTMLAttributes<HTMLTableSectionElement> & {
    classNameWrapper?: string;
    padding?: boolean;
    insertBeforeWrapper?: ReactNode | string;
  };

const Section: FC<TSection> = ({
  className = "",
  classNameWrapper = "",
  children,
  insertBeforeWrapper = null,
  ...last
}) => (
  <section className={className} {...last}>
    {insertBeforeWrapper}
    <Wrapper className={classNameWrapper}>{children}</Wrapper>
  </section>
);

export default Section;
