import { FIX_MATH_MONEY_OPERATION_COFF } from "settings";

export const getFixedValue = (value: number | string, numberFixed = 2) => {
  return Number(value).toFixed(numberFixed);
};

export const getCorrectSumTwoMoney = (
  sum1: number | string,
  sum2: number | string,
  fixed = false
) => {
  const result = (Number(sum1) * FIX_MATH_MONEY_OPERATION_COFF +
    Number(sum2) * FIX_MATH_MONEY_OPERATION_COFF) /
    FIX_MATH_MONEY_OPERATION_COFF;


  return fixed ? getFixedValue(result) : result;
};

export const multiplyPrices = (
  price: number | string,
  count: number,
  fixed = true
) => {
  const value =
    (Number(price) * FIX_MATH_MONEY_OPERATION_COFF * count) /
    FIX_MATH_MONEY_OPERATION_COFF;

  if (fixed) {
    return getFixedValue(value) as string;
  }

  return value as number;
};
