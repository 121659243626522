import { FC } from "react";
import classNames from "classnames";
import { TProductBadge } from "types";
import css from "./Badge.module.scss";

type TBadge = TProductBadge & {
  classNameItem?: string;
};

const getVariantBadgeColorClass = (text: string) => {
  switch (text) {
    case "Горячо":
      return "hot";
    case "Новинка":
      return "newProduct";
    // case "Горячие продажи":
    //   return "delivery";
    case "Подарок":
      return "gift";
    default:
      return "gift";
  }
};
const Badge: FC<TBadge> = ({ title, classNameItem = "" }) => {
  const classNameVariant = getVariantBadgeColorClass(title);

  return (
    <span
      className={classNames(
        css.badge,
        {
          [classNameItem]: classNameItem,
        },
        [css[classNameVariant]]
      )}
    >
      {title}
    </span>
  );
};

export default Badge;
