import { FC } from "react";
import TextContentBlockPhoto from "./TextContentBlockPhoto";
import { TProductPhotoInfo } from "types";

type TTextContentBlockGallery = {
  images: TProductPhotoInfo[];
};

const TextContentBlockGallery: FC<TTextContentBlockGallery> = ({ images }) => (
  <div className="text-content__gallery">
    {images.map((photoData, index) => {
      return <TextContentBlockPhoto key={index} {...photoData} />;
    })}
  </div>
);

export default TextContentBlockGallery;
