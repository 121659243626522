import { FC } from "react";
import { TProductAttributes } from "types";
import css from "./Product.module.scss";

type TProductAttribute = { title: string; text: string | number };
const addAttributeItem = (
  arr: TProductAttribute[],
  title: string,
  text: string | number
) => {
  arr.push({
    title,
    text,
  });
};

const ProductAttributesText: FC<TProductAttributes> = ({
  brand,
  category,
  colors,
  structure,
  series,
  size,
}) => {
  const attributes: TProductAttribute[] = [];

  if (category) {
    addAttributeItem(attributes, "Категория", category.title);
  }

  if (brand && brand.title) {
    addAttributeItem(attributes, "Бренд", brand.title);
  }

  if (colors) {
    addAttributeItem(attributes, "Цвет", colors);
  }

  if (structure) {
    addAttributeItem(attributes, "Материал", structure);
  }

  if (series && series.title) {
    addAttributeItem(attributes, "Серия", series.title);
  }

  if (size) {
    addAttributeItem(attributes, "Размеры", size);
  }

  return (
    <>
      {attributes.map((attributeData, index) => {
        return (
          <p className={css.attribute_item} key={index}>
            <span className={css.attribute_title}>{attributeData.title}: </span>
            {attributeData.text}
          </p>
        );
      })}
    </>
  );
};

export default ProductAttributesText;
