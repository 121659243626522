import { FC, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { Footer, Header, PageTransition } from "components";

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const { pathname } = useLocation();
  // const currentOutlet = useOutlet(); // not <Outlet /> - because it'll call rerender and second screen will show immediately

  return (
    <>
      <Header />
      <PageTransition route={pathname}>{children}</PageTransition>
      <Footer />
    </>
  );
};

export default Layout;
