import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getCountCartElements,
  getLocalStorageItem,
  multiplyPrices,
} from "utils";
import {
  LOCALSTORAGE_ITEM_NAME,
  MAX_PRODUCTS_ITEM_ONE_KIND,
  MIN_PRODUCTS_ITEM_ONE_KIND,
} from "settings";
import { TCart } from "types";

export type TCartState = {
  cart: TCart;
  countItems: number;
};

const getLocalStorageCartInfo = () => {
  const cartFromLocalStorage = getLocalStorageItem(LOCALSTORAGE_ITEM_NAME);
  const startState: TCartState = {
    cart: {},
    countItems: 0,
  };

  if (Object.keys(cartFromLocalStorage).length === 0) return startState;

  try {
    for (const prodKey in cartFromLocalStorage) {
      const countItems = +cartFromLocalStorage[prodKey];
      if (countItems < 1) {
        continue;
      }
      startState.cart[prodKey] = countItems;
      startState.countItems += countItems;
    }

    return startState;
  } catch (error) {
    return startState;
  }
};

const cartInitialState: TCartState = getLocalStorageCartInfo();

export const cartSlice = createSlice({
  name: "cart",
  initialState: cartInitialState,
  reducers: {
    clearCart: (state) => {
      state.cart = {};
      state.countItems = 0;
    },

    removeElement: (state, { payload }: PayloadAction<string>) => {
      delete state.cart[payload];
      state.countItems = getCountCartElements(state.cart);
    },

    increaseCartElement: (state, { payload }: PayloadAction<string>) => {
      const newCount = state.cart[payload] ? state.cart[payload] + 1 : 1;

      if (newCount > MAX_PRODUCTS_ITEM_ONE_KIND) return;

      state.cart[payload] = newCount;
      state.countItems = getCountCartElements(state.cart);
    },

    decreaseCartElement: (state, { payload }: PayloadAction<string>) => {
      if (!(payload in state.cart)) return;

      const cartItemCount = state.cart[payload];

      if (cartItemCount && cartItemCount > MIN_PRODUCTS_ITEM_ONE_KIND) {
        const newCount = cartItemCount - 1;
        state.cart[payload] = newCount;
        state.countItems = getCountCartElements(state.cart);
        return;
      }

      delete state.cart[payload];
      state.countItems = getCountCartElements(state.cart);
    },
  },
});

export const {
  clearCart,
  increaseCartElement,
  decreaseCartElement,
  removeElement,
} = cartSlice.actions;
