import { PREFIX_IMGS } from "./store";

export const PAGES_LINKS = {
  home: "/",
  shop: "/shop",
  category: "/shop/:category",
  product: "/shop/:category/:product",
  delivery: "/delivery",
  payment: "/payment",
  about: "/about",
  publicOffer: "/public-offer",
  personalInfo: "/personal-info",
  bonuses: "/bonuses",
  help: "/help",
  cart: "/cart",
  order: "/order",
  errorPage: "/error-page",
  discounts: "/sales",
  testimonials: "/testimonials",
  new: "/new",
  leaders: "/leaders",
  profile: "/profile",
  notFoundPage: "/not-found",
  search: "/search",
  notFoundRoute: "*",
};

const getImagePath = (image: string): string => {
  return `${process.env.PUBLIC_URL}${PREFIX_IMGS}img/${image}`;
};

export const HEADER_TOP_NAV = [
  // { id: "0", link: PAGES_LINKS.publicOffer, text: "Оферта" },
  { id: "12", link: PAGES_LINKS.bonuses, text: "Бонусы" },
  { id: "1", link: PAGES_LINKS.delivery, text: "Доставка" },
  { id: "2", link: PAGES_LINKS.payment, text: "Оплата" },
  { id: "3", link: PAGES_LINKS.about, text: "О нас" },
  { id: "13", link: PAGES_LINKS.help, text: "Помощь" },
];

export const HEADER_DOWN_NAV = [
  {
    id: "1",
    link: PAGES_LINKS.discounts,
    text: "Cкидки",
    mobileImg: getImagePath("sales.png"),
  },
  {
    id: "4",
    link: PAGES_LINKS.leaders,
    text: "Лидеры",
    mobileImg: getImagePath("leaders.png"),
  },
  {
    id: "3",
    link: PAGES_LINKS.new,
    text: "Новинки",
    mobileImg: getImagePath("new.png"),
  },
  {
    id: "2",
    link: PAGES_LINKS.shop,
    text: "Каталог",
    mobileImg: getImagePath("categories.png"),
  },
  // { id: "2", link: PAGES_LINKS.testimonials, text: "Отзывы", mobileImg: getImagePath("testimonials.png") },
];

export const FOOTER_NAV = [
  {
    id: "1",
    link: PAGES_LINKS.home,
    text: "Главная",
    mobileImg: getImagePath("home.png"),
  },
  {
    id: "2",
    link: PAGES_LINKS.testimonials,
    text: "Отзывы",
    mobileImg: getImagePath("profile.png"),
  },
  {
    id: "3",
    link: PAGES_LINKS.shop,
    text: "Каталог",
    mobileImg: getImagePath("categories.png"),
  },
  {
    id: "4",
    link: PAGES_LINKS.cart,
    text: "Корзина",
    mobileImg: getImagePath("cart.png"),
  },
];
