import { FC, useEffect, useState } from "react";
import { useSwiper } from "swiper/react";
import {
  Fancybox,
  ProductPhotoOrSlider,
  ProductPrice,
  ProductCountBtns,
  ProductBadges,
  ProductPriceRange,
} from "components";
import ProductTabs from "./ProductTabs";
import VariationButton from "./VariationButton";
import ProductAttributesText from "./ProductAttributesText";
import ProductDescription from "./ProductDescription";
import { getMinMaxProductsPrices, getProductPhotosData } from "utils";
import { TProduct } from "types";

import css from "./Product.module.scss";

//  TODO: при открытии fancybox активная картинка в слайдере меняется
// и при закрытии не коректно отображается выбранный вариант и картинка в слайдере

const ProductVariable: FC<TProduct> = ({
  id,
  title,
  badge,
  brand,
  category,
  description,
  images,
  colors,
  series,
  size,
  structure,
  quantity,
  variants,

  price,
  sale_price,
  sales,
  height_side,
  long_side,
  square,
  weight,
  weight_side,
}) => {
  const [chosenProductId, setChosenProductId] = useState<number | null>(null);
  const [photoSlideIndex, setPhotoSlideIndex] = useState<number | null>(null);

  const photosForSlider = getProductPhotosData(title, images, variants);
  const lengthPhotos = images.length;
  const changeChosenProductId = (id: number) => {
    if (id === chosenProductId) return;

    setChosenProductId(id);

    const indexPhoto = variants.findIndex((prod) => prod.id === id);
    setPhotoSlideIndex(() => {
      if (~indexPhoto) {
        return lengthPhotos + 1 + indexPhoto;
      } else {
        return null;
      }
    });
  };

  const tabsContent = [
    {
      buttonTitle: "Описание",
      content: <ProductDescription description={description} />,
    },
    {
      buttonTitle: "Характеристики",
      content: (
        <ProductAttributesText
          brand={brand}
          category={category}
          colors={colors}
          structure={structure}
          series={series}
          size={size}
        />
      ),
    },
  ];

  const { minPrice, maxPrice } = getMinMaxProductsPrices(
    variants,
    "price",
    "sale_price"
  );

  const infoChosenProduct = variants?.filter(
    (product) => product.id === chosenProductId
  )[0];

  return (
    <>
      <Fancybox>
        <ProductPhotoOrSlider
          images={photosForSlider}
          needNavigation={true}
          needPagination={true}
          needButtonWrapper={true}
          itemClassName={css.product__photo}
          sliderClassName={css.product__slider}
          slideChangeTime={10000}
        >
          <ChangeSliderActiveItem
            photoSlideIndex={photoSlideIndex}
            activeItem={chosenProductId}
          />
        </ProductPhotoOrSlider>
      </Fancybox>

      <ProductBadges
        classNameWrapper={css.product_badges}
        badges={badge}
        Tag="span"
      />

      <div className={css.variations}>
        {variants.map(({ id: varId, thumbnail, title }) => (
          <VariationButton
            key={varId}
            id={varId}
            changeChosenProductId={changeChosenProductId}
            chosenProductId={chosenProductId}
            thumbnail={thumbnail}
            name={title}
          />
        ))}
      </div>

      <div className={css.product__sale_info}>
        {chosenProductId && infoChosenProduct ? (
          <ProductPrice
            basePrice={infoChosenProduct.price}
            salePrice={infoChosenProduct.sale_price}
            className={css.product__price}
            Tag="span"
          />
        ) : (
          <ProductPriceRange
            Tag="span"
            minPrice={minPrice}
            maxPrice={maxPrice}
            className={css.product__price}
          />
        )}

        {/* {(sales || quantity) && (
          <div className={css.product__sale_info_item}>
            {sales && <p>Продано {sales}</p>}
            {quantity && <p>Осталось {quantity}</p>}
          </div>
        )} */}
      </div>

      {Boolean(chosenProductId) ? (
        <ProductCountBtns
          idParent={id}
          quantity={quantity}
          id={chosenProductId}
          className={css.product__buttons}
        />
      ) : (
        <p className={css.not_chosen_product_text}>
          Для покупки выберите один из вариантов товаров
        </p>
      )}

      <ProductTabs tabsData={tabsContent} />
    </>
  );
};

type TChangeActiveSlide = {
  activeItem: number | null;
  photoSlideIndex: number | null;
};
const ChangeSliderActiveItem: FC<TChangeActiveSlide> = ({
  activeItem,
  photoSlideIndex,
}) => {
  const swiper = useSwiper();

  useEffect(() => {
    if (!swiper) return;

    if ("autoplay" in swiper && "stop" in swiper.autoplay) {
      swiper.autoplay.stop();
    }

    if (photoSlideIndex && "slideTo" in swiper) {
      swiper.slideTo(photoSlideIndex);
    }
  }, [activeItem, photoSlideIndex, swiper]);

  return null;
};

export default ProductVariable;
