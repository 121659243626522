import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    showHeaderTop: true,
    showBackButton: false,
    showMainPageButton: false,
    showSearch: true,
  },
  reducers: {
    changeShowHeaderTopNav: (state, { payload }: { payload: boolean }) => {
      state.showHeaderTop = payload;
    },
    changeShowBackButton: (state, { payload }: { payload: boolean }) => {
      state.showBackButton = payload;
    },
    changeShowMainPageButton: (state, { payload }: { payload: boolean }) => {
      state.showMainPageButton = payload;
    },
    changeShowHeaderSearch: (state, { payload }: { payload: boolean }) => {
      state.showSearch = payload;
    },
    changeHeaderOptions: (
      state,
      {
        payload,
      }: {
        payload: {
          showHeaderTop: boolean;
          showBackButton: boolean;
          showMainPageButton: boolean;
          showSearch: boolean;
        };
      }
    ) => {
      state.showHeaderTop = payload.showHeaderTop;
      state.showBackButton = payload.showBackButton;
      state.showMainPageButton = payload.showMainPageButton;
      state.showSearch = payload.showSearch;
    },
  },
});

export const {
  changeShowHeaderTopNav,
  changeShowBackButton,
  changeShowMainPageButton,
  changeShowHeaderSearch,
  changeHeaderOptions,
} = appSlice.actions;
