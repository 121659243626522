import { FC } from "react";
import { TextContentBlock } from "components";

const ProductDescription: FC<{ description: string | null }> = ({ description }) => {
  if (!description) return null;

  return <TextContentBlock dangerouslySetInnerHTML={{ __html: description }} />;
};

export default ProductDescription;
