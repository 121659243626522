import { FC, HTMLAttributes, ReactNode } from "react";

type TTextContentBlock = HTMLAttributes<HTMLDivElement> & {
  children?: ReactNode;
};

const TextContentBlock: FC<TTextContentBlock> = ({
  children,
  ...lastProps
}) => (
  <div className="text-content" {...lastProps}>
    {children}
  </div>
);

export default TextContentBlock;
