import { FC } from "react";
import classNames from "classnames";
import { CURRENCY_MARKER } from "settings";
import css from "./ProductPrice.module.scss";

type TProductPriceProps = {
  basePrice: string;
  salePrice?: string | null;
  Tag?: "span" | "p";
  className?: string;
};

const ProductPrice: FC<TProductPriceProps> = ({
  basePrice,
  salePrice,
  Tag = "p",
  className = "",
}) => (
  <Tag
    className={classNames(css.product_price, {
      [className]: className,
    })}
  >
    {salePrice ? (
      <>
        <span className={css.price}>{salePrice}</span>
        <span className={css.old__price}>
          {basePrice}
          <span className={css.currency}>{CURRENCY_MARKER}</span>
        </span>
      </>
    ) : (
      <>
        <span className={css.price}>
          {basePrice} <span className={css.currency}>{CURRENCY_MARKER}</span>
        </span>
      </>
    )}
  </Tag>
);

export default ProductPrice;
