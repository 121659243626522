import { useEffect } from "react";
import { changeHeaderOptions, useAppDispatch } from "store";
import {
  Layout,
  Section,
  TextContentBlock,
  TextContentBlockParagraph,
  Title,
} from "components";

const PublicOffer = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: true,
        showBackButton: false,
        showMainPageButton: false,
        showSearch: true,
      })
    );
  }, [dispatch]);

  return (
    <Layout>
      <Section className="page-section">
        <Title Tag="h1" text="ДоПУБЛИЧНАЯ ОФЕРТА!" />

        <TextContentBlock>
          <TextContentBlockParagraph>
            Интернет-магазин «Авторские аксессуары», расположенный на доменном
            имени http://acceseniya.ru/, Индивидуальный предприниматель
            Пивоварова Анна Александровна, e-mail адрес: a.a.pivovarova@mail.ru,
            действующий на основании листа записи ЕГРИП (ИНН 263108327642, ОГРН
            322265100131456), удостоверяющий полномочия и его реквизиты,
            именуемое в дальнейшем «Продавец», публикует Публичную оферту о
            продаже Товара дистанционным способом.
          </TextContentBlockParagraph>

          <Title Tag="h2" topMargin text="1.	ОПРЕДЕЛЕНИЕ ТЕРМИНОВ" />

          <TextContentBlockParagraph>
            1.1. Публичная оферта (далее – «Оферта») - публичное предложение
            Продавца, адресованное неопределенному кругу лиц, заключить с
            Продавцом договор купли-продажи товара дистанционным способом (далее
            - «Договор») на условиях, содержащихся в настоящей Оферте, включая
            все Приложения.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            1.2. Заказ Товара на сайте Интернет-магазина – позиции, указанные
            Покупателем из ассортимента Товара, предложенного к продаже, при
            оформлении заявки на приобретение Товара на сайте Интернет-магазина
            или через Оператора.
          </TextContentBlockParagraph>

          <Title Tag="h2" topMargin text="2.	ОБЩИЕ ПОЛОЖЕНИЯ" />

          <TextContentBlockParagraph>
            2.1. Заказ Покупателем Товара, размещенного на сайте
            Интернет-магазина, означает, что Покупатель согласен со всеми
            условиями настоящей Оферты.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            2.2. Администрация сайта Интернет-магазина имеет право вносить
            изменения в Оферту без уведомления Покупателя.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            2.3. Срок действия Оферты не ограничен, если иное не указано на
            сайте Интернет-магазина.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            2.4. Продавец предоставляет Покупателю полную и достоверную
            информацию о Товаре, включая информацию об основных потребительских
            свойствах Товара, месте изготовления, а также информацию о
            гарантийном сроке и сроке годности Товара на сайте Интернет
            магазина, в разделе Каталог.
          </TextContentBlockParagraph>

          <Title Tag="h2" topMargin text="3.	ЦЕНА ТОВАРА" />

          <TextContentBlockParagraph>
            3.1. Цена на каждую позицию Товара указана на сайте
            Интернет-магазина.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            3.2. Продавец имеет право в одностороннем порядке изменить цену на
            любую позицию Товара.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            3.3. В случае изменения цены на заказанный Товар Продавец обязуется
            в течение 1 (одного) дня проинформировать Покупателя об изменении
            цены Товара.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            3.4. Покупатель вправе подтвердить либо аннулировать Заказ на
            приобретение Товара, если цена изменена Продавцом после оформления
            Заказа.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            3.5. Изменение Продавцом цены на оплаченный Покупателем Товар не
            допускается.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            3.6. Продавец указывает стоимость доставки Товара на сайте
            Интернет-магазина либо сообщает Покупателю при оформлении заказа
            Оператором.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            3.7. Обязательства Покупателя по оплате Товара считаются
            исполненными с момента поступления Продавцом денежных средств.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            3.8. Расчеты между Продавцом и Покупателем за Товар производятся
            способами, указанными на сайте Интернет-магазина в разделе Оплата.
          </TextContentBlockParagraph>

          <Title Tag="h2" topMargin text="4. ОФОРМЛЕНИЕ ЗАКАЗА" />

          <TextContentBlockParagraph>
            4.1. Заказ Товара осуществляется Покупателем через сервис сайта
            Интернет-магазина acceseniya_group_bot Корзина.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            4.2. При регистрации на сайте Интернет-магазина Покупатель обязуется
            предоставить следующую регистрационную информацию:
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            4.2.1. фамилия, имя, отчество Покупателя или указанного им лица
            (получателя);
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            4.2.2. адрес, по которому следует доставить Товар (если доставка до
            адреса Покупателя);
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            4.2.3. адрес электронной почты;
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            4.2.4. контактный телефон.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            4.3. Наименование, количество, ассортимент, артикул, цена выбранного
            Покупателем Товара указываются в корзине Покупателя на сайте
            Интернет-магазина.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            4.4. Если Продавцу необходима дополнительная информация, он вправе
            запросить ее у Покупателя. В случае не предоставления необходимой
            информации Покупателем, Продавец не несет ответственности за
            выбранный Покупателем Товар.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            4.5. При оформлении Заказа через Оператора (п. 4.1. настоящей
            Оферты) Покупатель обязуется предоставить информацию, указанную в п.
            4.2. настоящей Оферты.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            4.6. Принятие Покупателем условий настоящей Оферты осуществляется
            посредством внесения Покупателем соответствующих данных в
            регистрационную форму на сайте Интернет-магазина или при оформлении
            Заказа через Оператора. После оформления Заказа через Оператора
            данные о Покупателе регистрируются в базе данных Продавца. Утвердив
            Заказ выбранного Товара, Покупатель предоставляет Оператору
            необходимую информацию в соответствии с порядком, указанном в п.
            4.2. настоящей Оферты.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            4.7. Продавец не несет ответственности за содержание и достоверность
            информации, предоставленной Покупателем при оформлении Заказа.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            4.8. Покупатель несет ответственность за достоверность
            предоставленной информации при оформлении Заказа.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            4.9. Договор купли-продажи дистанционным способом между Продавцом и
            Покупателем считается заключенным с момента выдачи Продавцом
            Покупателю кассового или товарного чека либо иного документа,
            подтверждающего оплату Товара.
          </TextContentBlockParagraph>

          <Title
            Tag="h2"
            topMargin
            text="5.	ДОСТАВКА И ПЕРЕДАЧА ТОВАРА ПОКУПАТЕЛЮ"
          />

          <TextContentBlockParagraph>
            5.1. Продавец оказывает Покупателю услуги по доставке Товара одним
            из способов, указанных на сайте Интернет-магазина.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            5.2. Если Договор купли-продажи товара дистанционным способом (далее
            – Договор) заключен с условием о доставке Товара Покупателю,
            Продавец обязан в установленный Договором срок доставить Товар в
            место, указанное Покупателем, а если место доставки Товара
            Покупателем не указано, то по месту его жительства или регистрации.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            5.3. Место доставки Товара Покупатель указывает при оформлении
            Заказа на приобретение Товара.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            5.4. Срок доставки Товара Покупателю состоит из срока обработки
            заказа и срока доставки.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            5.5. Доставленный Товар передается Покупателю, а при отсутствии
            Покупателя - любому лицу, предъявившему квитанцию или иной документ,
            подтверждающий заключение Договора или оформление доставки Товара.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            5.6. Информация о Товаре доводится до сведения Покупателя в
            технической документации, прилагаемой к Товару, на этикетках, путем
            нанесения маркировки или иным способом, принятым для отдельных видов
            товаров.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            5.7. Сведения об обязательном подтверждении соответствия Товара
            представляются в порядке и способами, которые установлены
            законодательством Российской Федерации о техническом регулировании,
            и включают в себя сведения о номере документа, подтверждающего такое
            соответствие, о сроке его действия и об организации, его выдавшей.
          </TextContentBlockParagraph>

          <Title Tag="h2" topMargin text="6. РЕКВИЗИТЫ ПРОДАВЦА" />

          <TextContentBlockParagraph>
            Продавец: Индивидуальный предприниматель Пивоварова Анна
            Александровна
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Телефон: +7-988-100-48-05
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Адрес: г. Ставрополь, ул. 50 лет ВЛКСМ 107
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Банковские реквизиты: Ставропольское отделение № 5230 филиала ПАО
            "Сбербанк России" К/с 30101810907020000615 БИК 040702615
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Р/сч 40802810960100093261
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Внимательно ознакомьтесь с текстом публичной оферты, и если Вы не
            согласны с каким-либо пунктом оферты, Вы вправе отказаться от
            покупки Товаров, предоставляемых Продавцом, и не совершать действий,
            указанный в п. 2.1. настоящей Оферты.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Согласен с договором
          </TextContentBlockParagraph>
        </TextContentBlock>
      </Section>
    </Layout>
  );
};

export default PublicOffer;
