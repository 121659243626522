import { FC } from "react";
import { TTestimonial } from "types";
import css from "./Testimonials.module.scss";

const TestimonialsListItem: FC<TTestimonial> = ({ author, content }) => {
  return (
    <li className={css.testimonials__item}>
      <p className={css.testimonials__item_name}>{author}</p>
      <p className={css.testimonials__item_content}>{content}</p>
    </li>
  );
};

export default TestimonialsListItem;
