import { FC, memo } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import {
  ProductBadges,
  ProductPhotoOrSlider,
  ProductPrice,
  ProductPriceRange,
} from "components";
import { getMinMaxProductsPrices, getProductPhotosData, getProductUrlLink } from "utils";
import { TIME_CHANGE_PRODUCT_SLIDE_CAT } from "settings";
import { TProduct } from "types";
import css from "./ProductsItem.module.scss";

export type TProductsItemProps = TProduct & {
  Tag?: "li" | "div";
  className?: string;
};

const ProductsItem: FC<TProductsItemProps> = ({
  title,
  slug,
  price,
  sale_price,
  category,
  variants,
  images,
  badge,
  className = "",
  Tag = "li",
}) => {
  const urlLink = getProductUrlLink(slug, category.slug);
  const itemClassName = classNames(css.product_item, {
    [className]: className,
  });

  const imagesForSlider = getProductPhotosData(title, images, variants);

  const { minPrice, maxPrice } = getMinMaxProductsPrices(
    variants,
    "price",
    "sale_price"
  );

  return (
    <Tag className={itemClassName}>
      <Link className={css.product_item__link} to={urlLink}>
        <ProductPhotoOrSlider
          images={imagesForSlider}
          itemClassName={css.product_item__link_img}
          sliderClassName={css.product_item__slider}
          slideChangeTime={TIME_CHANGE_PRODUCT_SLIDE_CAT}
        />

        <span className={css.product_item__link_description}>
          <ProductBadges
            classNameWrapper={css.product_badges}
            badges={badge}
            Tag="span"
          />

          <span className={css.product_item__link_name}>{title}</span>

          {Boolean(variants.length) ? (
            <ProductPriceRange
              Tag="span"
              minPrice={minPrice}
              maxPrice={maxPrice}
              className={css.product_item__price}
            />
          ) : (
            <ProductPrice
              basePrice={price}
              salePrice={sale_price}
              className={css.product_item__price}
              Tag="span"
            />
          )}
        </span>
      </Link>
    </Tag>
  );
};

export default memo(ProductsItem);
