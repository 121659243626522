import { useLayoutEffect } from "react";

const useChangeCssVarSize = (
  refItem: React.MutableRefObject<HTMLElement | null>,
  cssVar: string
) => {
  useLayoutEffect(() => {
    const checkSizeHeader = () => {
      if (!refItem.current) return;

      const heightHeader = refItem.current.getBoundingClientRect().height;
      document.body.style.setProperty(cssVar, heightHeader + "px");
    };

    checkSizeHeader();

    window.addEventListener("resize", checkSizeHeader);

    return () => {
      window.removeEventListener("resize", checkSizeHeader);
    };
  }, [refItem, cssVar]);
};

export { useChangeCssVarSize };
