import { useEffect } from "react";
import { changeHeaderOptions, useAppDispatch } from "store";
import { Layout, Section, Title } from "components";

const Bonuses = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: true,
        showBackButton: false,
        showMainPageButton: false,
        showSearch: true,
      })
    );
  }, [dispatch]);

  return (
    <Layout>
      <Section className="page-section">
        <Title Tag="h1" text="Бонусы" />
      </Section>
    </Layout>
  );
};

export default Bonuses;
