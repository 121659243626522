import { useEffect } from "react";
import { changeHeaderOptions, useAppDispatch } from "store";
import {
  Layout,
  Section,
  TextContentBlock,
  TextContentBlockParagraph,
  Title,
} from "components";

const Payment = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: true,
        showBackButton: false,
        showMainPageButton: false,
        showSearch: true,
      })
    );
  }, [dispatch]);

  return (
    <Layout>
      <Section className="page-section">
        <Title Tag="h1" text="Оплата товаров" />

        <TextContentBlock>
          <TextContentBlockParagraph>
            Представляем вашему вниманию авторскую линию аксессуаров для волос и
            бижутерии.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Милые девочки такие девочки, которые нуждаются в особом отношении к
            себе и к своей природной красоте.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Мы готовы дарить Вам прекрасные эмоции каждый день и желаем всегда
            заботиться в первую очередь о себе
          </TextContentBlockParagraph>
        </TextContentBlock>
      </Section>
    </Layout>
  );
};

export default Payment;
