import { FC } from "react";
import { Link } from "react-router-dom";
import { getCatUrlLink, getPhotoOrDefaultImgLink } from "utils";
import { TCategory } from "types";
import css from "./CategoriesListItem.module.scss";

const CategoriesListItem: FC<TCategory> = ({ slug, title, thumbnail }) => {
  const urlLink = getCatUrlLink(slug);

  return (
    <li className={css.cat_item}>
      <Link className={css.cat_item__link} to={urlLink}>
        <span className={css.cat_item__img}>
          <img src={getPhotoOrDefaultImgLink(thumbnail)} alt={title} />
        </span>

        <span className={css.cat_item__name}>{title}</span>
      </Link>
    </li>
  );
};

export default CategoriesListItem;
