import { FC } from "react";
import classNames from "classnames";
import { TCommonComponent } from "types";
import css from "./Wrapper.module.scss";

const Wrapper: FC<TCommonComponent> = ({ children, className = "" }) => (
  <div className={classNames(css.wrapper, className)}>{children}</div>
);

export default Wrapper;
