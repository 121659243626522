import { FC } from "react";
import { TCommonComponent, TSendForm, TSimpleFunction } from "types";

type TFormProps = TCommonComponent & {
  sendForm: TSimpleFunction | TSendForm;
};

const Form: FC<TFormProps> = ({ sendForm, children, className }) => (
  <form className={className ? className : "form"} onSubmit={sendForm}>
    {children}
  </form>
);

export default Form;
