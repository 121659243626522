import { FC } from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "components";
import { PAGES_LINKS } from "settings";
import css from "./Header.module.scss";

type THeaderNavIconButtonsProps = {
  showBackButton?: boolean;
  showMainPageButton?: boolean;
};

const HeaderNavIconButtons: FC<THeaderNavIconButtonsProps> = ({
  showBackButton = false,
  showMainPageButton = false,
}) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className={css.direction_wrapper}>
      {showBackButton && (
        <Button
          className={classNames(css.back_link, {
            [css.line]: showBackButton && showMainPageButton,
          })}
          onClick={goBack}
        >
          {null}
        </Button>
      )}
      {showMainPageButton && (
        <Link className={css.home_link} to={PAGES_LINKS.home}></Link>
      )}
    </div>
  );
};

export default HeaderNavIconButtons;
