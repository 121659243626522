import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOneProductQuery } from "service";
import { changeHeaderOptions, useAppDispatch } from "store";
import { Layout, Section, Spinner, Title } from "components";
import ProductVariable from "./ProductVariable";
import ProductSimple from "./ProductSimple";
import { PAGES_LINKS } from "settings";
import { TProduct } from "types";
import css from "./Product.module.scss";

const Product = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { product = "" } = useParams();

  const {
    currentData: productData = {},
    isError: isErrorProductData,
    // isSuccess: isSuccessProductData,
    // error: ProductDataErrorData,
    isLoading: isProductDataLoading,
  } = useGetOneProductQuery({
    slug: product,
  });

  console.log("Product page", productData);

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: false,
        showBackButton: true,
        showMainPageButton: true,
        showSearch: false,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (isErrorProductData && !productData) {
      navigate(PAGES_LINKS.notFoundPage);
    }
  }, [isErrorProductData, productData, navigate]);

  const prodInfo = productData as TProduct;

  return (
    <Layout>
      <Section className={css.product}>
        {isProductDataLoading ? (
          <Spinner />
        ) : (
          <>
            <Title
              Tag="h1"
              text={prodInfo.title}
              className={css.product__title}
            />

            {prodInfo.variants.length ? (
              <ProductVariable {...prodInfo} />
            ) : (
              <ProductSimple {...prodInfo} />
            )}
          </>
        )}
      </Section>
    </Layout>
  );
};

export default Product;
