import { FC, FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { clearCart } from "store/cartSlice";
import { useTelegram } from "hooks/useTelegram";
import { Link, useNavigate } from "react-router-dom";
import { Form, FormElementWrapperWithError, Input } from "components";
import OrderContentAddressCDEKSimple from "./OrderContentAddressCDEKSimple";
import {
  MIN_LENGTH_INPUT_NAME,
  MIN_LENGTH_INPUT_PHONE,
  PAGES_LINKS,
} from "settings";
import { IFormOrderState, TChangeElHandler } from "types";

import css from "./OrderContent.module.scss";
import { useCartItems } from "hooks";
import { useAddOrderMutation } from "service";

type IOrderContentForm = {
  deliverySum: number;
};

const startFormState: IFormOrderState = {
  address: "",
  fullAddress: "",
  name: "",
  phone: "",
  question: "",
};

const OrderContentForm: FC<IOrderContentForm> = ({ deliverySum }) => {
  const navigate = useNavigate();
  const cart = useAppSelector((state) => state.cart.cart);
  const dispatch = useAppDispatch();
  const { sendTelegram, userId } = useTelegram();
  const { cartProducts, isCartEmpty, totalSum } = useCartItems();
  // const [
  //   createOrder,
  //   {
  //     data: createOrderData = { data: { order: -1 } },
  //     isLoading: isLoadingCreateOrder,
  //     isError: isErrorCreateOrder,
  //     isSuccess: isSuccessCreateOrder,
  //   },
  // ] = useAddOrderMutation();

  const [formState, setFormState] = useState<IFormOrderState>({
    ...startFormState,
  });

  const [showAddressError, setShowAddressError] = useState<boolean>(false);
  const [showNameError, setShowNameError] = useState<boolean>(false);
  const [showPhoneError, setShowPhoneError] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(
    totalSum === "0.00" || showAddressError || showNameError || showPhoneError
  );
  const [idDeliveryUser, setIdDeliveryUser] = useState<string | null>(null);

  const sendFormHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitDisabled(true);
    let errorExist = false;

    if (formState.name.trim().length < MIN_LENGTH_INPUT_NAME) {
      setShowNameError(true);
      errorExist = true;
    }

    if (formState.phone.trim().length < MIN_LENGTH_INPUT_PHONE) {
      setShowPhoneError(true);
      errorExist = true;
    }

    if (formState.address.trim().length === 0) {
      setShowAddressError(true);
      errorExist = true;
    }

    if (errorExist) {
      // setFormState({ ...startFormState });
      setShowError(true);
      setSubmitDisabled(false);
      return;
    }

    // const data = new FormData(event.target as HTMLFormElement);
    // const answer = await sendLetter("/send.php", data);
    // console.log("answer", answer);

    try {
      const dataForTelegram = JSON.stringify({
        products: cart,
        deliveryInfo: formState,
        deliverySum,
        isOrder: true,
      });

      sendTelegram(dataForTelegram);
    } catch (error) {
      console.log("error", error);
    }
    setFormState({ ...startFormState });
    setSubmitDisabled(false);
    setShowError(false);
    navigate(PAGES_LINKS.home);
    setSubmitDisabled(false);
    dispatch(clearCart());
  };

  const changeFormState: TChangeElHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = ({ target }) => {
    if (!(target.name in formState)) return;

    if (target.name === "name") setShowNameError(false);
    if (target.name === "phone") setShowPhoneError(false);

    setFormState((prev) => {
      return {
        ...prev,
        [target.name]: target.value,
      };
    });
  };

  const changeIdUser = (id: string | null) => {
    setIdDeliveryUser(id);
  };

  if (isCartEmpty) {
    return null;
  }

  return (
    <>
      {/* <OrderContentAddress
        idDeliveryUser={idDeliveryUser}
        changeIdUser={changeIdUser}
      /> */}

      {/* <OrderContentAddressCDEK
        setFormState={setFormState}
        setShowAddressError={setShowAddressError}
        showAddressError={showAddressError}
      /> */}
      <OrderContentAddressCDEKSimple
        setFormState={setFormState}
        setShowAddressError={setShowAddressError}
        showAddressError={showAddressError}
      />

      <Form sendForm={sendFormHandler}>
        <FormElementWrapperWithError
          inputTitle="Ваше ФИО"
          required={true}
          errorShow={showNameError}
          errorText={`Необходимо ввести имя. Минимально ${MIN_LENGTH_INPUT_NAME} символов.`}
        >
          <Input
            type="text"
            name="name"
            placeholder="Иванова"
            required
            value={formState.name}
            onChange={changeFormState}
            isControlled={true}
          />
        </FormElementWrapperWithError>

        <FormElementWrapperWithError
          inputTitle="Телефон"
          required={true}
          errorShow={showPhoneError}
          errorText={`Необходимо ввести телефон. Минимально ${MIN_LENGTH_INPUT_PHONE} символов.`}
        >
          <Input
            type="tel"
            name="phone"
            placeholder="+375 29 123-45-67"
            required
            value={formState.phone}
            onChange={changeFormState}
            isControlled={true}
          />
        </FormElementWrapperWithError>

        <FormElementWrapperWithError
          className={css.form_question}
          errorShow={false}
          errorText=""
          inputTitle="Сообщение"
        >
          <textarea
            name="question"
            value={formState.question}
            onChange={changeFormState}
            placeholder="Сообщение"
          ></textarea>
        </FormElementWrapperWithError>

        {showError && (
          <p className={css.form__error}>
            При оформлении заказа произошла ошибка, попробуйте отправить позже
          </p>
        )}

        <Input
          className="form__submit"
          type="submit"
          submitBtnText="Сделать заказ"
          disabled={submitDisabled}
        />

        <p className="form__approved">
          Размещая заказ, вы соглашаетесь с правилами{" "}
          <Link to={PAGES_LINKS.publicOffer}>
            оферты и условиями отработки персональных данных
          </Link>
        </p>
      </Form>
    </>
  );
};

export default OrderContentForm;
