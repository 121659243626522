import { FC, memo } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "store/store";
import { removeElement } from "store/cartSlice";
import { Button, ProductCountBtns } from "components";
import { TCartItem } from "types";
import css from "./CartProductItem.module.scss";

const CartProductItem: FC<TCartItem & { disabled: boolean }> = ({
  id,
  parentId,
  structure,
  title,
  quantity,
  slug,
  price,
  sale_price,
  thumbnail,
  total,
  count,
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const removeFromCardProduct = () => {
    dispatch(removeElement(id));
  };

  return (
    <li className={css.cart_item}>
      <Button
        className={css.cart_item__remove}
        onClick={removeFromCardProduct}
        title="Удалить из корзины товар"
        disabled={disabled}
      >
        &#10005;
      </Button>

      <Link className={css.cart_item__link} to={slug}>
        <img
          className={css.item_img}
          src={process.env.PUBLIC_URL + thumbnail}
          alt={title}
        />

        <span className={css.item_name}>{title}</span>
      </Link>

      {structure && (
        <p className={css.attributes}>
          <span className={css.attributes__item}>
            <span className={css.attributes__item_key}>Состав: </span>
            {structure}
          </span>
        </p>
      )}

      <div className={css.item_count}>
        <ProductCountBtns
          id={id}
          idParent={parentId}
          quantity={quantity}
          disabled={disabled}
        />

        <p className={css.item__count_separator}>*</p>
        <p className={css.item__count_price}>
          {sale_price ? sale_price : price}
        </p>
        <p className={css.item__count_separator}>=</p>
        <p className={css.item__count_total}>{total}</p>
      </div>
    </li>
  );
};

export default memo(CartProductItem);
