// @ts-nocheck
import { FC, useState } from "react";
import classNames from "classnames";
import { Button, TextContentBlockParagraph } from "components";
import { getDeliveries } from "service/delivery";

import css from "./OrderContent.module.scss";

type TOrderContentAddressCDEKSimple = {
  setFormState: Dispatch<SetStateAction<IFormOrderState>>;
  setShowAddressError: Dispatch<SetStateAction<boolean>>;
  showAddressError: boolean;
};

const OrderContentAddressCDEKSimple: FC<TOrderContentAddressCDEKSimple> = ({
  setFormState,
  showAddressError,
  setShowAddressError,
}) => {
  const { 0: city, 1: setCity } = useState("");
  const { 0: street, 1: setStreet } = useState("");
  const { 0: house, 1: setHouse } = useState("");

  const deliveries = getDeliveries();
  const addAddress = (city, street, house) => {
    if (!city || !street || !house) {
      setFormState((prev) => ({
        ...prev,
        address: "",
        fullAddress: "",
      }));
      setShowAddressError(true);
      return;
    }

    const { code, name } = deliveries[city][street][house];
    const address = `Город: ${city}. Улица: ${street}. Дом: ${house}.`;
    const fullAddress = `Код: ${code}. Название: ${name}. ${address}`;
    setFormState((prev) => ({
      ...prev,
      address,
      fullAddress,
    }));
    setShowAddressError(false);
  };

  const changeCity = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const cityValue = event.currentTarget.value;
    setCity(cityValue);
    setStreet("");
    setHouse("");

    if (!cityValue) return;

    const streets = Object.keys(deliveries[cityValue]);
    if (streets.length === 1) {
      const currentStreet = streets.length ? streets[0] : "";
      setStreet(currentStreet);

      const houses = Object.keys(deliveries[cityValue][currentStreet]);
      if (houses.length <= 1) {
        const currentHouse = houses.length ? houses[0] : "";
        setHouse(currentHouse);
        addAddress(cityValue, currentStreet, currentHouse);
      }
    }
  };

  const changeStreet = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const streetValue = event.currentTarget.value;
    setStreet(streetValue);
    setHouse("");

    if (!streetValue) return;

    const houses = Object.keys(deliveries[city][streetValue]);
    if (houses.length <= 1) {
      const currentHouse = houses.length ? houses[0] : "";

      setHouse(currentHouse);
      addAddress(city, streetValue, currentHouse);
    }
  };

  const changeHouse = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const currentHouse = event.currentTarget.value;

    setHouse(currentHouse);
    addAddress(city, street, currentHouse);
  };

  return (
    <div className={css.deliveryContent}>
      <p className={css.deliveryContent__title}>
        Способ доставки: СДЭК
      </p>

      <p className={css.deliveryContent__choose_subtitle}>
        Выберите адрес: ПВЗ
      </p>

      <div className={css.deliveryContent__choose}>
        <p className={css.deliveryContent__choose_title}>Город:</p>
        <select onChange={changeCity} value={city}>
          <option value="">Выберите город</option>
          {Object.keys(deliveries)
            .sort()
            .map((city, ind) => (
              <option value={city} key={ind}>
                {city}
              </option>
            ))}
        </select>
      </div>

      {city && (
        <div className={css.deliveryContent__choose}>
          <p className={css.deliveryContent__choose_title}>Улица:</p>

          <select onChange={changeStreet} value={street}>
            <option value="">Выберите улицу</option>
            {Object.keys(deliveries[city])
              .sort()
              .map((street, ind) => (
                <option value={street} key={ind}>
                  {street}
                </option>
              ))}
          </select>
        </div>
      )}

      {street && (
        <div className={css.deliveryContent__choose}>
          <p className={css.deliveryContent__choose_title}>№ дома:</p>

          <select onChange={changeHouse} value={house}>
            <option value="">Выберите дом</option>
            {Object.keys(deliveries[city][street])
              .sort()
              .map((house, ind) => (
                <option value={house} key={ind}>
                  {house}
                </option>
              ))}
          </select>
        </div>
      )}

      {city in deliveries &&
        street in deliveries[city] &&
        house in deliveries[city][street] &&
        deliveries[city][street][house].addressComment && (
          <p className={css.deliveryContent__info}>
            Комментарий: {deliveries[city][street][house].addressComment}
          </p>
        )}

      {showAddressError && (
        <p className={css.deliveryContent__error}>Выберите адрес доставки</p>
      )}
    </div>
  );
};

export default OrderContentAddressCDEKSimple;
