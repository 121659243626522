import { FC } from "react";
import classNames from "classnames";
import { CURRENCY_MARKER } from "settings";

import css from "./ProductPrice.module.scss";

type TProductPriceRangeProps = {
  Tag?: "span" | "p";
  className?: string;
  minPrice: string | null;
  maxPrice: string | null;
};

const ProductPriceRange: FC<TProductPriceRangeProps> = ({
  Tag = "p",
  className = "",
  minPrice,
  maxPrice,
}) => {
  if (!minPrice && !maxPrice) return null;

  return (
    <Tag
      className={classNames(css.product_price, {
        [className]: className,
      })}
    >
      {minPrice === maxPrice ? (
        <>
          <span className={css.price}>{minPrice}</span>
          <span className={css.currency}>{CURRENCY_MARKER}</span>
        </>
      ) : (
        <>
          <span className={css.price}>
            <span className={css.price_text}>от</span> {minPrice}
            <span className={css.currency}>{CURRENCY_MARKER}</span>
          </span>

          <span className={css.separator}> - </span>

          <span className={css.price}>
            <span className={css.price_text}>до</span> {maxPrice}
            <span className={css.currency}>{CURRENCY_MARKER}</span>
          </span>
        </>
      )}
    </Tag>
  );
};

export default ProductPriceRange;
