import { FC } from "react";
import classNames from "classnames";
import { TCommonComponent } from "types";
import css from "./Table.module.scss";

const Table: FC<TCommonComponent> = ({ className = "", children }) => (
  <table
    className={classNames(css.table, {
      [className]: className,
    })}
  >
    {children}
  </table>
);

export default Table;
