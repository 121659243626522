import { FC, ReactNode } from "react";
import { SwiperSlide } from "swiper/react";
import { Slider } from "components";
import ProductPhoto from "./ProductPhoto";
import { TIME_CHANGE_PRODUCT_SLIDE_ONE_PRODUCT } from "settings";
import { TProductPhotoInfo } from "types";

type TProductPhotoOrSliderProps = {
  sliderClassName?: string;
  itemClassName?: string;
  images: Array<TProductPhotoInfo>;
  needNavigation?: boolean;
  needPagination?: boolean;
  needButtonWrapper?: boolean;
  slideChangeTime?: number;
  children?: ReactNode;
};

const ProductPhotoOrSlider: FC<TProductPhotoOrSliderProps> = ({
  itemClassName,
  sliderClassName,
  images = null,
  needNavigation = false,
  needPagination = false,
  needButtonWrapper = false,
  slideChangeTime = TIME_CHANGE_PRODUCT_SLIDE_ONE_PRODUCT,
  children,
}) => {
  if (!images || images.length === 0) return null;

  if (images.length === 1) {
    return (
      <ProductPhoto
        needButtonWrapper={needButtonWrapper}
        img={images[0].url}
        className={itemClassName}
        title={images[0].alt}
      />
    );
  }

  return (
    <Slider
      className={sliderClassName ? sliderClassName : ""}
      needNavigation={needNavigation}
      needPagination={needPagination}
      timeAutoPlay={slideChangeTime}
      autoplay={false}
    >
      {images.map(({ url, alt }, index) => (
        <SwiperSlide tag="li" key={index}>
          <ProductPhoto
            needButtonWrapper={needButtonWrapper}
            img={url}
            className={itemClassName}
            title={alt}
          />
        </SwiperSlide>
      ))}
      {children}
    </Slider>
  );
};

export default ProductPhotoOrSlider;
