import { useEffect } from "react";
import { useGetCategoriesQuery } from "service";
import { useAppDispatch, changeHeaderOptions } from "store";
import { CategoriesList, Layout, Section, Spinner, Title } from "components";
import css from "./Shop.module.scss";

const Shop = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: false,
        showBackButton: true,
        showMainPageButton: false,
        showSearch: true,
      })
    );
  }, [dispatch]);

  const {
    currentData: categories = [],
    // isError: isErrorCategoriesAdd,
    // isSuccess: isSuccessCategoriesAdd,
    // error: categoriesAddErrorData,
    isLoading: isCategoriesAddLoading,
  } = useGetCategoriesQuery(null);

  return (
    <Layout>
      <Section className={css.shop} classNameWrapper={css.wrapper}>
        <Title Tag="h1" text="Магазин" className={css.title} />

        {isCategoriesAddLoading ? (
          <Spinner />
        ) : (
          <CategoriesList cats={categories} />
        )}
      </Section>
    </Layout>
  );
};

export default Shop;
