import { FC } from "react";
import TestimonialsListItem from "./TestimonialsListItem";
import { TTestimonial } from "types";
import css from "./Testimonials.module.scss";

type TTestimonials = {
  testimonials: TTestimonial[];
};

const TestimonialsList: FC<TTestimonials> = ({ testimonials }) => {
  if (testimonials.length === 0) return null;

  return (
    <ul className={css.testimonials}>
      {testimonials.map((testimonial) => {
        return <TestimonialsListItem key={testimonial.id} {...testimonial} />;
      })}
    </ul>
  );
};

export default TestimonialsList;
