import React, { FC } from "react";
import { Button, Input, Form } from "components";
import css from "./PaginationItem.module.scss";
import { TChangeElHandler, TSendForm } from "types";

type TPaginationItemProps = {
  currentPage: number;
  currentInput: number;
  number: number;
  content: string | number;
  stopClick: boolean;
  clickPaginationButton: (id: number) => void;
  changeInputHandler: TChangeElHandler<HTMLInputElement>;
  submitHandler: TSendForm;
};

const PaginationItem: FC<TPaginationItemProps> = ({
  currentPage,
  currentInput,
  content,
  number,
  stopClick,
  clickPaginationButton,
  changeInputHandler,
  submitHandler,
}) => (
  <li className={css.pagination_item}>
    {content === currentPage ? (
      <Form sendForm={submitHandler} className={css.pagination_item__current}>
        <Input
          className={css.pagination_item__current_number}
          type="number"
          name="current"
          isControlled={true}
          value={currentInput.toString()}
          onChange={changeInputHandler}
          disabled={stopClick}
        />
        <Input
          className={css.pagination_item__current_submit}
          type="submit"
          disabled={stopClick}
          submitBtnText="Change page"
        />
      </Form>
    ) : (
      <Button
        disabled={stopClick}
        className={css.pagination_item__button}
        onClick={() => clickPaginationButton(number)}
      >
        <span>{content.toString()}</span>
      </Button>
    )}
  </li>
);

export default PaginationItem;
