import {
  COUNT_PRODUCTS_PER_PAGE,
  PAGES_LINKS,
  PRODUCT_IMAGE_DEFAULT,
} from "settings";
import { getFixedValue } from "./index";
import {
  TProductImage,
  TProductVariant,
  TCart,
  TProduct,
  TProductPhotoInfo,
} from "types";

const SITE_URL_LINK = process.env.PUBLIC_URL;

export const getCountCartElements = (cart: TCart) => {
  let countItems = 0;

  for (const key in cart) {
    countItems += cart[key];
  }

  return countItems;
};

export const getCatUrlLink = (categorySlug?: string): string => {
  return `${SITE_URL_LINK + PAGES_LINKS.shop}/${
    categorySlug ? categorySlug + "/" : ""
  }`;
};

export const getProductUrlLink = (
  slug: string,
  categorySlug?: string
): string => {
  const catLink = getCatUrlLink(categorySlug);
  return catLink + slug;
};

export const getProductPhotosData = (
  startName: string,
  images: TProductImage[],
  variants: TProductVariant[]
): TProductPhotoInfo[] => {
  let photosArray: TProductPhotoInfo[] = [];
  let startIndexForName = 1;

  images.forEach((photo) => {
    photosArray.push({
      url: photo.thumbnail,
      alt: startName + " - " + startIndexForName + ".",
    });
    startIndexForName++;
  });

  variants.forEach(({ thumbnail, title }) => {
    photosArray.push({
      url: thumbnail || PRODUCT_IMAGE_DEFAULT,
      alt: title || startName + " - " + startIndexForName + ".",
    });
    startIndexForName++;
  });

  if (photosArray.length === 0) {
    photosArray.push({
      url: PRODUCT_IMAGE_DEFAULT,
      alt: "Нет картинки для товара - " + startName + ".",
    });
  }

  return photosArray;
};

export const getPhotoOrDefaultImgLink = (photo: string | null): string => {
  return photo ? photo : PRODUCT_IMAGE_DEFAULT;
};

export const getSortedArrayByKeyField = <T extends unknown>(
  cats: T[],
  key: keyof T
) => {
  return cats.toSorted((a: T, b: T): number => {
    if (!a[key] && !b[key]) {
      return 0;
    } else if (a[key] && !b[key]) {
      return -1;
    } else if (!a[key] && b[key]) {
      return 1;
    } else if (a[key] && b[key] && a[key] > b[key]) {
      return -1;
    }

    return 1;
  });
};

export const changeResponseProductsData = (response: {
  results: TProduct[];
}) => {
  if (response.results) {
    return response.results;
  }

  return [];
};

export const getMaxPageNumber = (countItems: number) =>
  Math.ceil(countItems / COUNT_PRODUCTS_PER_PAGE);

type TMinMaxPricesResult = {
  minPrice: string | null;
  maxPrice: string | null;
};

export const getMinMaxProductsPrices = <T>(
  products: T[],
  keyPrice: keyof T,
  salePrice: keyof T
): TMinMaxPricesResult => {
  const allPrices: number[] = [];
  const result: TMinMaxPricesResult = { maxPrice: null, minPrice: null };

  products.forEach((variant) => {
    if (variant[keyPrice]) {
      allPrices.push(+variant[keyPrice]);
    }

    if (variant[salePrice]) {
      allPrices.push(+variant[salePrice]);
    }
  });

  if (!allPrices.length) return result;

  allPrices.sort();

  return {
    minPrice: getFixedValue(allPrices[0]),
    maxPrice: getFixedValue(allPrices[allPrices.length - 1]),
  };
};

type TCheckValue = (
  value: number,
  comparisonValue: number,
  variant: "more" | "less"
) => boolean;

export const checkValue: TCheckValue = (
  value: number,
  comparisonValue: number,
  variant: "more" | "less" = "more"
): boolean => {
  if (variant === "more") {
    return value >= comparisonValue;
  }

  return value <= comparisonValue;
};
