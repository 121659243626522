import { useCartItems } from "hooks";
import OrderContentTable from "./OrderContentTable";
import OrderContentForm from "./OrderContentForm";

const OrderContent = () => {
  const deliverySum = 500;
  const { isCartEmpty } = useCartItems();

  if (isCartEmpty) return null;

  return (
    <>
      <OrderContentTable deliverySum={deliverySum} />
      <OrderContentForm deliverySum={deliverySum} />
    </>
  );
};

export default OrderContent;
