import { FC } from "react";
import CategoriesListItem from "./CategoriesListItem/CategoriesListItem";
import { getSortedArrayByKeyField } from "utils";
import { TCategory } from "types";
import css from "./CategoriesList.module.scss";

type TCatList = {
  cats: TCategory[];
};

const CategoriesList: FC<TCatList> = ({ cats }) => {
  if (cats.length === 0) return null;

  return (
    <ul className={css.catalog__list}>
      {getSortedArrayByKeyField<TCategory>(cats, "sort").map((catInfo) => {
        return <CategoriesListItem key={catInfo.id} {...catInfo} />;
      })}
    </ul>
  );
};

export default CategoriesList;
