import { FC } from "react";
import classNames from "classnames";
import { TCommonComponent } from "types";
import css from "./TableBody.module.scss";

const TableBody: FC<TCommonComponent> = ({ className = "", children }) => (
  <tbody
    className={classNames(css.table_body, {
      [className]: className,
    })}
  >
    {children}
  </tbody>
);

export default TableBody;
