import { FC } from "react";
import { getPhotoOrDefaultImgLink } from "utils";

type TProductPhotoOrSliderProps = {
  className?: string;
  img: string;
  title: string;
  needButtonWrapper: boolean;
};

const ProductPhoto: FC<TProductPhotoOrSliderProps> = ({
  className,
  img,
  title,
  needButtonWrapper,
}) => {
  const classNamePhoto = className ? className : "";
  const photoUrl = getPhotoOrDefaultImgLink(img);

  if (needButtonWrapper) {
    return (
      <button
        data-fancybox="gallery"
        data-src={photoUrl}
        className={classNamePhoto}
      >
        <img src={photoUrl} alt={title} />
      </button>
    );
  }

  return (
    <span className={classNamePhoto}>
      <img src={photoUrl} alt={title} />
    </span>
  );
};

export default ProductPhoto;
