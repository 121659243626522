import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { changeHeaderOptions } from "store/appSlice";
import { useAppDispatch } from "store/store";
import { Layout, Section, ShopLink, Title } from "components";
import css from "./ErrorPage.module.scss";

const ErrorPage = () => {
  const { state } = useLocation();
  let errorText = "";

  if (state && typeof state === "object" && errorText in state) {
    errorText = state.errorText;
  }

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: true,
        showBackButton: false,
        showMainPageButton: false,
        showSearch: true,
      })
    );
  }, [dispatch]);

  return (
    <Layout>
      <Section className={css.error_page}>
        <Title Tag="h1" text="Что-то пошло не так." />

        <p className={css.error_text}>{errorText || "Неизвестная ошибка!"}</p>

        <ShopLink>Вернуться в магазин</ShopLink>
      </Section>
    </Layout>
  );
};

export default ErrorPage;
