import { FC } from "react";
import { useCartItems } from "hooks";
import { getCorrectSumTwoMoney, getFixedValue } from "utils";
import {
  ProductPrice,
  Table,
  TableBody,
  TableHead,
  TableItem,
  TableLine,
} from "components";
import css from "./OrderContent.module.scss";

export type IOrderContentTable = {
  deliverySum: number;
};

const OrderContentTable: FC<IOrderContentTable> = ({ deliverySum }) => {
  const { cartProducts, isCartEmpty, totalSum } = useCartItems();

  if (isCartEmpty) return null;

  return (
    <div className={css.table__wrapper}>
      <Table className={css.table}>
        <TableHead>
          <TableLine>
            <TableItem Tag="th">Название</TableItem>
            <TableItem Tag="th">Количество, шт</TableItem>
            <TableItem Tag="th">Цена, руб</TableItem>
            <TableItem Tag="th">Сумма, руб</TableItem>
          </TableLine>
        </TableHead>

        <TableBody>
          {cartProducts.map((product) => {
            const { id, title, price, sale_price, count, total } = product;

            return (
              <TableLine key={id}>
                <TableItem Tag="td">{title}</TableItem>
                <TableItem Tag="td">{count}</TableItem>
                <TableItem Tag="td">
                  <ProductPrice
                    basePrice={price}
                    salePrice={sale_price}
                    className={css.price}
                  />
                </TableItem>
                <TableItem Tag="td">{total}</TableItem>
              </TableLine>
            );
          })}

          <TableLine className={css.table_bold_line}>
            <TableItem Tag="td">Доставка, руб:</TableItem>
            <TableItem Tag="td" colSpan={3}>
              {getFixedValue(deliverySum)}
            </TableItem>
          </TableLine>

          <TableLine className={css.table_bold_line}>
            <TableItem Tag="td">Итог, руб:</TableItem>
            <TableItem Tag="td" colSpan={3}>
              {getCorrectSumTwoMoney(totalSum, deliverySum, true)}
            </TableItem>
          </TableLine>
        </TableBody>
      </Table>
    </div>
  );
};

export default OrderContentTable;
