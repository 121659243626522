import { useEffect, useState } from "react";
import { changeHeaderOptions, useAppDispatch } from "store";
import { useGetNewProductsQuery } from "service";
import { CommonCategoryPart, Title } from "components";
import { SORT_DIRECTION, SORT_VARIANTS } from "settings";
import { TSortDirection } from "types";

const NewProducts = () => {
  const dispatch = useAppDispatch();
  const [sortVariant, setSortVariant] = useState<SORT_VARIANTS>(
    SORT_VARIANTS.name
  );
  const [sortDirection, setSortDirection] = useState<TSortDirection>(
    SORT_DIRECTION.ASC
  );

  const {
    currentData: saleProducts = { results: [] },
    // isError: isErrorSale,
    // isSuccess: isSuccessSale,
    // error: saleErrorData,
    isLoading: isSaleLoading,
  } = useGetNewProductsQuery({
    sortDirection,
    sortVariant,
  });

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: false,
        showBackButton: true,
        showMainPageButton: false,
        showSearch: true,
      })
    );
  }, [dispatch]);

  return (
    <CommonCategoryPart
      countItems={1}
      currentPage={1}
      sortVariant={sortVariant}
      sortDirection={sortDirection}
      products={saleProducts.results}
      isLoading={isSaleLoading}
      isProductsLoading={isSaleLoading}
      changePageCb={() => {}}
      changeSortVariantCb={setSortVariant}
      changeSortDirectionCb={setSortDirection}
    >
      <Title Tag="h1" text="Новинки!" />
    </CommonCategoryPart>
  );
};

export default NewProducts;
