import { FC, ReactNode } from "react";
import { Navigation, Pagination, EffectFade, A11y, Autoplay } from "swiper";
import { Swiper } from "swiper/react";
import { AutoplayOptions } from "swiper/types";

import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "./slider.scss";

type TSliderProps = {
  className?: string;
  spaceBetween?: number;
  slidesPerView?: number;
  needPagination?: boolean;
  needNavigation?: boolean;
  timeAutoPlay?: number;
  autoplay?: boolean | AutoplayOptions | undefined;
  timeSpeed?: number;
  children?: ReactNode;
  wrapperTag?: "ul" | "div";
};

const Slider: FC<TSliderProps> = ({
  className,
  spaceBetween = 0,
  slidesPerView = 1,
  needPagination = false,
  needNavigation = true,
  timeAutoPlay = 1000,
  autoplay = true,
  timeSpeed = 600,
  wrapperTag = "ul",
  children,
}) => {
  const modulesSlider = [Autoplay, A11y, EffectFade];
  const swiperOptions: {
    navigation?: boolean;
    pagination?: { clickable: boolean };
  } = {};

  if (needNavigation) {
    modulesSlider.push(Navigation);
    swiperOptions["navigation"] = true;
  }

  if (needPagination) {
    modulesSlider.push(Pagination);
    swiperOptions["pagination"] = { clickable: true };
  }

  const autoplayOptions = {
    delay: timeAutoPlay,
    disableOnInteraction: false,
  };

  return (
    <Swiper
      className={className}
      modules={modulesSlider}
      slidesPerView={slidesPerView}
      {...swiperOptions}
      spaceBetween={spaceBetween}
      wrapperTag={wrapperTag}
      effect="fade"
      loop={true}
      speed={timeSpeed}
      autoplay={autoplay ? autoplayOptions : autoplay}
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log("slide change")}
    >
      {children}
    </Swiper>
  );
};

export default Slider;
