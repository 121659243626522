import { Nav, Wrapper } from "components";
import { FOOTER_NAV } from "settings";
import css from "./Footer.module.scss";

const Footer = () => (
  <footer className={css.footer}>
    <Wrapper className={css.wrapper}>
      <Nav
        classNameNav={css.nav}
        classNameList={css.nav__list}
        classNameItem={css.nav__list_item}
        classNameLink={css.anchor}
        imagesIcons={true}
        links={FOOTER_NAV}
      />
    </Wrapper>
  </footer>
);

export default Footer;
