import { TTestimonial } from "types";

export const TESTIMONIALS_LIST: TTestimonial[] = [
  {
    id: "1",
    author: "Petr Yan",
    content:
      "Content content content content content. Content content content content content. Content content content content content. Content content content content content",
  },
  {
    id: "2",
    author: "Vasiliy Yan",
    content:
      "Content content content content content. Content content content content content. Content content content content content. Content content content content content",
  },
  {
    id: "3",
    author: "Ivan Yan",
    content:
      "Content content content content content. Content content content content content. Content content content content content. Content content content content content",
  },
];
