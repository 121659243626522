import { FC, ReactNode } from "react";
import classNames from "classnames";
import css from "./Title.module.scss";

type TTitle = {
  Tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  className?: string;
  topMargin?: boolean;
  text: ReactNode | string;
};

const Title: FC<TTitle> = ({
  Tag = "h1",
  text,
  topMargin = false,
  className = css.title,
}) => (
  <Tag className={classNames(className, { [css.mt]: topMargin })}>{text}</Tag>
);

export default Title;
