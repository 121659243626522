import { useMemo } from "react";
import { useGetProductsFromIdsQuery } from "service";
import { PRODUCT_IMAGE_DEFAULT } from "settings";
import { useAppSelector } from "store";
import {
  getCorrectSumTwoMoney,
  getFixedValue,
  getProductUrlLink,
  multiplyPrices,
} from "utils";
import { TCartItem } from "types";

type TNewCartWithInfo = {
  cartProducts: TCartItem[];
  isCartEmpty: boolean;
  isLoadingCartProducts: boolean;
  isErrorCartProducts: boolean;
  totalSum: string;
};

const useCartItems = (): TNewCartWithInfo => {
  const cart = useAppSelector((state) => state.cart.cart);

  const idsForSearch: string[] = useMemo(() => {
    const idsForSearch: string[] = [];
    for (const key in cart) {
      const parentId = key.split("-")[0];
      idsForSearch.push(parentId);
    }
    return idsForSearch;
  }, [cart]);

  const {
    data: cartProducts = [],
    isLoading,
    isError,
    isSuccess,
  } = useGetProductsFromIdsQuery(idsForSearch);

  const isCartEmpty = cartProducts.length === 0;

  const cartItems = useMemo(() => {
    const items: TCartItem[] = [];
    for (const key in cart) {
      if (cart[key] < 1) {
        continue;
      }

      const keyIds = key.split("-");
      const parentId = keyIds[0];
      const variantId = keyIds[1];
      const itemInfo = cartProducts.filter((item) => item.id === +parentId)[0];

      if (!itemInfo) {
        continue;
      }

      const cartItemData: TCartItem = {
        id: variantId ? variantId : parentId,
        parentId: variantId ? parentId : null,
        title: itemInfo.title,
        quantity: 1,
        slug: getProductUrlLink(itemInfo.slug, itemInfo.category.slug),
        price: "",
        sale_price: "",
        thumbnail: PRODUCT_IMAGE_DEFAULT,
        total: "",
        count: cart[key],
        structure: itemInfo.structure,
      };

      if (keyIds.length > 1) {
        const variantItem = itemInfo.variants.filter(
          (item) => item.id === +variantId
        )[0];

        if (variantItem) {
          cartItemData.title =
            cartItemData.title + " Вариант: " + variantItem.title;
          cartItemData.price = variantItem.price;
          cartItemData.sale_price = variantItem.sale_price;
          if (variantItem.thumbnail) {
            cartItemData.thumbnail = variantItem.thumbnail;
          }
        }
      } else {
        cartItemData.price = itemInfo.price;
        cartItemData.sale_price = itemInfo.sale_price;

        if (itemInfo.images.length > 0) {
          cartItemData.thumbnail = itemInfo.images[0].thumbnail;
        }
      }

      if (cartItemData.sale_price) {
        cartItemData.total = multiplyPrices(
          cartItemData.sale_price,
          cartItemData.count,
          true
        ) as string;
      } else if (cartItemData.price) {
        cartItemData.total = multiplyPrices(
          cartItemData.price,
          cartItemData.count,
          true
        ) as string;
      }

      items.push(cartItemData);
    }

    return items;
  }, [cartProducts, cart]);

  const totalSum: string =
    getFixedValue(
      cartItems.reduce(
        (acc, prev) => getCorrectSumTwoMoney(acc, prev.total) as number,
        0
      )
    ) || "0";

  // useEffect(() => {
  //   if (isError) {
  //     setNewCart({ products: [], countItems: 0, totalSum: 0 });
  //   }
  // }, [isError]);

  // useEffect(() => {
  //   if (isSuccess) {
  //     let countItems = 0;
  //     let totalSum = 0;

  //     setNewCart({
  //       products: [], //cartProducts,
  //       countItems: countItems,
  //       totalSum: totalSum,
  //     });
  //   }
  // }, [isSuccess]);

  return {
    cartProducts: cartItems,
    isCartEmpty,
    isLoadingCartProducts: isLoading,
    isErrorCartProducts: isError,
    totalSum,
  };
};

export { useCartItems };
