import classNames from "classnames";
import { FC } from "react";
import { TCommonComponent } from "types";
import css from "./FormElementWrapperWithError.module.scss";

type TFormElementWrapperWithError = TCommonComponent & {
  errorText: string;
  errorShow: boolean;
  required?: boolean;
  inputTitle?: string;
};

const FormElementWrapperWithError: FC<TFormElementWrapperWithError> = ({
  errorText,
  errorShow,
  inputTitle,
  className = "",
  children,
  required = false,
}) => (
  <label
    className={classNames(css.input__wrapper, {
      [css.error]: errorShow,
      [className]: className,
      [css.required]: required,
    })}
  >
    {inputTitle && <span className={css.input__title}>{inputTitle}</span>}
    {children}
    {errorShow && <span className={css.error__text}>{errorText}</span>}
  </label>
);

export default FormElementWrapperWithError;
