import { useRef } from "react";
import { useChangeCssVarSize } from "hooks/useChangeCssVarSize";
import { Nav, ProductSearchForm, Wrapper } from "components";
import HeaderNavIconButtons from "./HeaderNavIconButtons";
import { useAppSelector } from "store";
import { HEADER_TOP_NAV } from "settings";
import css from "./Header.module.scss";

const Header = () => {
  const headerRef = useRef<HTMLElement | null>(null);
  const { showHeaderTop, showBackButton, showMainPageButton, showSearch } =
    useAppSelector((state) => state.app);
  useChangeCssVarSize(headerRef, "--header-size");

  return (
    <header className={css.header} ref={headerRef}>
      <Wrapper className={css.wrapper}>
        {showHeaderTop && (
          <Nav
            classNameNav={css.nav_top}
            classNameList={css.nav_top_list}
            classNameItem={css.nav_top_list_item}
            classNameLink={css.anchor}
            imagesIcons={false}
            links={HEADER_TOP_NAV}
          />
        )}

        <div className={css.header_down}>
          {(showBackButton || showMainPageButton) && (
            <HeaderNavIconButtons
              showBackButton={showBackButton}
              showMainPageButton={showMainPageButton}
            />
          )}

          {showSearch && <ProductSearchForm />}
        </div>
      </Wrapper>
    </header>
  );
};

export default Header;
