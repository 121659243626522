import { useCallback } from "react";
import {
  useIsTelegramWebAppReady,
  useTelegramWebApp,
} from "react-telegram-webapp";

export type IUseTelegram = {
  sendTelegram: (msg: string) => boolean;
  telegram: any;
  telegramReady: boolean;
  userId: string;
};

const useTelegram = (): IUseTelegram => {
  const telegramReady = useIsTelegramWebAppReady();
  const telegram = useTelegramWebApp();
  const userId = telegram?.initDataUnsafe?.user?.id || "";

  console.log("telegram", telegram, "userId", userId);

  const sendTelegram = useCallback(
    (msg: string): boolean => {
      if (!telegramReady) {
        return false;
      }
      telegram.sendData(msg);
      telegram.close();

      return true;
    },
    [telegram, telegramReady]
  );

  return {
    userId,
    sendTelegram,
    telegram,
    telegramReady,
  };
};

export { useTelegram };
