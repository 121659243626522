import { FC, ReactNode, useState } from "react";
import classNames from "classnames";
import { Button } from "components";
import css from "./Product.module.scss";

type TTabContent = {
  buttonTitle: string;
  content: ReactNode;
};

type TProductTabsProps = {
  tabsData: TTabContent[];
};

const ProductTabs: FC<TProductTabsProps> = ({ tabsData }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  if (tabsData.length === 0) return null;

  const changeActiveTab = (id: number) => {
    if (id !== activeTab) {
      setActiveTab(id);
    }
  };

  return (
    <div className={css.tabs}>
      <div className={css.tabs__buttons}>
        {tabsData.map((tabInfo, index) => {
          return (
            <Button
              className={classNames(css.tabs__buttons_item, {
                [css.active]: index === activeTab,
              })}
              key={index}
              onClick={() => changeActiveTab(index)}
            >
              {tabInfo.buttonTitle}
            </Button>
          );
        })}
      </div>

      <div className={css.tabs__content}>{tabsData[activeTab].content}</div>
    </div>
  );
};

export default ProductTabs;
