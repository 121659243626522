import { PREFIX_IMGS } from "./store";

export const LOCALSTORAGE_ITEM_NAME = "asl12jjnlr5y";
export const URL_PRODUCTS = process.env.PUBLIC_URL;
export const PRODUCT_IMAGE_DEFAULT = `${URL_PRODUCTS}${PREFIX_IMGS}img/default.png`;

export const FIX_MATH_MONEY_OPERATION_COFF = 1000;
export const CURRENCY_PRODUCTS = "руб.";

export const enum SORT_VARIANTS {
  price = "price",
  name = "title",
  sales = "sales",
}

export const enum SORT_DIRECTION {
  ASC = "ASC",
  DESC = "DESC",
}

export const MIN_PRODUCTS_ITEM_ONE_KIND = 1;
export const MAX_PRODUCTS_ITEM_ONE_KIND = 50;

export const COUNT_PRODUCTS_PER_PAGE = 12;
export const COUNT_NEW_PRODUCTS = 12;
export const COUNT_SALE_PRODUCTS = 12;
export const COUNT_LEADERS_PRODUCTS = 12;

export const COUNT_DISCOUNT_PRODUCTS = 2;
export const MIN_PAGINATION_PAGE = 1;
export const MAX_PHOTOS_ONE_PRODUCT_IN_SLIDER_CAT_PAGE = 5;
export const TIME_CHANGE_PRODUCT_SLIDE_CAT = 3000;
export const TIME_CHANGE_PRODUCT_SLIDE_ONE_PRODUCT = 3000;

// export const PRICE_DELIVERY = 3;
// export const MIN_SUM_DELIVERY = 18;
// export const MIN_SUM_FREE_DELIVERY = 25;
