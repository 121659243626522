import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { PAGES_LINKS } from "settings";
import css from "./ShopLink.module.scss";

type TShopLinkProps = {
  className?: string;
  children: ReactNode;
};

const ShopLink: FC<TShopLinkProps> = ({ className, children }) => (
  <Link
    to={PAGES_LINKS.shop}
    className={className ? className : css.button + " button"}
  >
    {children}
  </Link>
);

export default ShopLink;
