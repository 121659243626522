import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_TAGS, SERVICE_API_URL, SORT_VARIANTS } from "settings";
import { TCategory, TProductsResponse, TSortDirection } from "types";

const endpoints = {
  categories: "categories/",
  oneCategory: "category/",
  categoryProducts: "categories/",
};

type TCategoryBodyRequest = {
  limit?: number;
  offset?: number;
  categoryName: string;
  sortVariant: SORT_VARIANTS;
  sortDirection: TSortDirection;
};

export const categoriesApi = createApi({
  reducerPath: "categoriesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVICE_API_URL}`,
  }),
  tagTypes: [API_TAGS.products],
  endpoints: (builder) => ({
    getCategories: builder.query<TCategory[], null>({
      query: () => ({
        url: endpoints.categories,
      }),
    }),

    getCategoryInfo: builder.query<TCategory, string>({
      query: (categoryName) => ({
        url: `${endpoints.oneCategory}${categoryName}/`,
      }),
    }),

    getCategoryProducts: builder.query<TProductsResponse, TCategoryBodyRequest>(
      {
        query: ({
          offset,
          limit,
          categoryName,
          sortVariant,
          sortDirection,
        }) => {
          return {
            url: `${endpoints.categoryProducts}${categoryName}/?limit=${
              limit || 1
            }&offset=${
              offset || 0
            }&variant=${sortVariant}&direction=${sortDirection}`,
          };
        },
      }
    ),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetCategoryProductsQuery,
  useGetCategoryInfoQuery,
  useLazyGetCategoryProductsQuery,
} = categoriesApi;
