import { HistoryRouter as Router } from "redux-first-history/rr6";
import { Route, Routes } from "react-router-dom";
import { history } from "store/store";
import {
  Home,
  About,
  Bonuses,
  Cart,
  Category,
  Delivery,
  DiscountsProducts,
  ErrorPage,
  Help,
  LeadersProducts,
  NewProducts,
  NotFoundPage,
  Order,
  Payment,
  // PersonalInfo,
  Product,
  PublicOffer,
  SearchPage,
  Shop,
  Testimonials,
} from "pages";
import { PAGES_LINKS } from "settings";

export const SiteRouter = () => (
  <Router history={history}>
    <Routes>
      <Route path={PAGES_LINKS.home} element={<Home />} />
      <Route path={PAGES_LINKS.about} element={<About />} />
      <Route path={PAGES_LINKS.delivery} element={<Delivery />} />
      <Route path={PAGES_LINKS.payment} element={<Payment />} />
      <Route path={PAGES_LINKS.publicOffer} element={<PublicOffer />} />
      <Route path={PAGES_LINKS.bonuses} element={<Bonuses />} />
      <Route path={PAGES_LINKS.help} element={<Help />} />
      {/* <Route path={PAGES_LINKS.personalInfo} element={<PersonalInfo />} /> */}
      <Route path={PAGES_LINKS.discounts} element={<DiscountsProducts />} />
      <Route path={PAGES_LINKS.new} element={<NewProducts />} />
      <Route path={PAGES_LINKS.leaders} element={<LeadersProducts />} />
      <Route path={PAGES_LINKS.shop} element={<Shop />} />
      <Route path={PAGES_LINKS.category} element={<Category />} />
      <Route path={PAGES_LINKS.product} element={<Product />} />
      <Route path={PAGES_LINKS.cart} element={<Cart />} />
      <Route path={PAGES_LINKS.order} element={<Order />} />
      <Route path={PAGES_LINKS.search} element={<SearchPage />} />
      <Route path={PAGES_LINKS.testimonials} element={<Testimonials />} />
      <Route path={PAGES_LINKS.errorPage} element={<ErrorPage />} />
      <Route path={PAGES_LINKS.notFoundRoute} element={<NotFoundPage />} />
    </Routes>
  </Router>
);
