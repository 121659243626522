import React, { ButtonHTMLAttributes, FC } from "react";
import { TCommonComponent, TClickFC } from "types";

type TButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  TCommonComponent & {
    onClick: TClickFC;
  };

const Button: FC<TButtonProps> = ({
  className = "button",
  onClick,
  children,
  ...lastProps
}) => (
  <button className={className} onClick={onClick} {...lastProps}>
    {children}
  </button>
);

export default Button;
