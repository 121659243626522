import { useEffect } from "react";
import { changeHeaderOptions } from "store/appSlice";
import { useAppDispatch } from "store/store";
import {
  Layout,
  Section,
  TextContentBlock,
  TextContentBlockGallery,
  TextContentBlockParagraph,
  TextContentBlockPhoto,
  Title,
} from "components";
import { URL_PRODUCTS } from "settings";
import { PREFIX_IMGS } from "settings/store";

const galleryPhotos = [
  {
    url: `${URL_PRODUCTS}${PREFIX_IMGS}img/about.JPG`,
    alt: "photo about",
  },

  {
    url: `${URL_PRODUCTS}${PREFIX_IMGS}img/about2.jpeg`,
    alt: "photo 2",
  },
  {
    url: `${URL_PRODUCTS}${PREFIX_IMGS}img/about3.jpeg`,
    alt: "photo 3",
  },
];

const About = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: true,
        showBackButton: false,
        showMainPageButton: false,
        showSearch: true,
      })
    );
  }, [dispatch]);

  return (
    <Layout>
      <Section className="page-section">
        <Title Tag="h1" text="Дорогие друзья!" />

        <TextContentBlock>
          <TextContentBlockPhoto url={`${URL_PRODUCTS}${PREFIX_IMGS}img/about.JPG`} alt="photo 1" />

          <TextContentBlockParagraph>
            Представляем вашему вниманию авторскую линию аксессуаров для волос и
            бижутерии.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Милые девочки такие девочки, которые нуждаются в особом отношении к
            себе и к своей природной красоте. С вниманием и трепетом относимся к
            каждой выпускаемой серии. Специально для вас разрабатываем дизайн и
            производим аксессуары в атмосфере добра, прекрасного настроения и
            любви к своему делу.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Каждая серия отражает глубину наших к вам чувств.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Здесь вы найдете кружевные наборы для причесок на романтический
            вечер. Яркие и блестящие резинки и заколки для вечеринок и ваших
            личных праздников. А также, наши лаконичные аксессуары помогут вам
            создать красивую, и в то же время простую прическу на короткие,
            средние или длинные волосы.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Ленты и банты, ободки, повязки, резинки для волос, заколки и наборы
            на каждый день, на праздник, день рождения, поход в кино и прогулки
            на свежем воздухе.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Каждую неделю выпускаем новинки, которые дополнят ваш образ в важный
            момент жизни.
          </TextContentBlockParagraph>

          <TextContentBlockParagraph>
            Мы дарим Вам прекрасные эмоции каждый день и желаем всегда
            заботиться в первую очередь о себе.
          </TextContentBlockParagraph>

          <TextContentBlockGallery images={galleryPhotos} />
        </TextContentBlock>
      </Section>
    </Layout>
  );
};

export default About;
