import { useEffect } from "react";
import { changeHeaderOptions, useAppDispatch } from "store";
import { Layout, Section, ShopLink, Title } from "components";

import css from "./NotFoundPage.module.scss";

const NotFoundPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: true,
        showBackButton: false,
        showMainPageButton: false,
        showSearch: true,
      })
    );
  }, [dispatch]);

  return (
    <Layout>
      <Section className={css.error_page}>
        <Title Tag="h1" className={css.error_title} text="404" />

        <p className={css.error_text}>Данной страницы не найдено :(</p>

        <ShopLink>Вернуться в магазин</ShopLink>
      </Section>
    </Layout>
  );
};

export default NotFoundPage;
