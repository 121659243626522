import { useEffect } from "react";
import classNames from "classnames";
import { changeHeaderOptions, useAppDispatch } from "store";
import { Layout, Section, Title } from "components";
import OrderContent from "./OrderContent";
import css from "./Order.module.scss";

const Order = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: true,
        showBackButton: false,
        showMainPageButton: false,
        showSearch: true,
      })
    );
  }, [dispatch]);

  return (
    <Layout>
      <Section
        className={classNames(css.order, "page-section")}
        classNameWrapper={css.wrapper}
      >
        <Title Tag="h1" text="Заказ" />

        <OrderContent />
      </Section>
    </Layout>
  );
};

export default Order;
