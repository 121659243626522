import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { PAGES_LINKS } from "settings";

type TProps = {
  children: ReactNode;
};

type TState = {
  hasError: boolean;
  errorText: string;
};

class ErrorBoundary extends React.PureComponent<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = { hasError: false, errorText: "" };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorText: error.message || "" };
  }

  showErrorPage = () => {
    const errorText = this.state.errorText;
    this.setState({ hasError: false, errorText: "" });

    return (
      <Navigate to={PAGES_LINKS.errorPage} replace state={{ errorText }} />
    );
  };

  render() {
    if (this.state.hasError) {
      return this.showErrorPage();
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
