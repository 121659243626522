import { FC, useCallback } from "react";
import classNames from "classnames";
import { Button } from "components";
import { SORT_DIRECTION, SORT_VARIANTS } from "settings";
import { TSortDirection } from "types";
import css from "./CategoryFilters.module.scss";

type TCategoryFilters = {
  changeSortOptions: (
    variant: SORT_VARIANTS,
    sortDirection: TSortDirection
  ) => void;
  sortVariant: SORT_VARIANTS;
  sortDirection: TSortDirection;
  disabledButtons?: boolean;
};

const CategoryFilters: FC<TCategoryFilters> = ({
  changeSortOptions,
  sortVariant,
  sortDirection,
  disabledButtons = false,
}) => {
  const changeNameSort = useCallback(() => {
    changeSortOptions(SORT_VARIANTS.name, sortDirection);
  }, [changeSortOptions, sortDirection]);

  const changeSaleSort = useCallback(() => {
    changeSortOptions(SORT_VARIANTS.sales, sortDirection);
  }, [changeSortOptions, sortDirection]);

  const changePriceSort = useCallback(() => {
    changeSortOptions(SORT_VARIANTS.price, sortDirection);
  }, [changeSortOptions, sortDirection]);

  const getButtonClassName = (key: SORT_VARIANTS) => {
    return classNames(css.filter_button, {
      [css.active]: sortVariant === key,
      [css.arrowUp]:
        sortVariant === key && sortDirection === SORT_DIRECTION.DESC,
    });
  };

  return (
    <div className={css.filters}>
      <Button
        className={getButtonClassName(SORT_VARIANTS.name)}
        onClick={changeNameSort}
        disabled={disabledButtons}
      >
        Названию
      </Button>

      <Button
        className={getButtonClassName(SORT_VARIANTS.sales)}
        onClick={changeSaleSort}
        disabled={disabledButtons}
      >
        Продажам
      </Button>

      <Button
        className={getButtonClassName(SORT_VARIANTS.price)}
        onClick={changePriceSort}
        disabled={disabledButtons}
      >
        Цене
      </Button>
    </div>
  );
};

export default CategoryFilters;
