import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createReduxHistoryContext } from "redux-first-history";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { createBrowserHistory } from "history";
import { categoriesApi, ordersApi, productsApi } from "service";
import { appSlice } from "./appSlice";
import { cartSlice } from "./cartSlice";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
    savePreviousLocations: 1,
  });

export const store = configureStore({
  reducer: combineReducers({
    app: appSlice.reducer,
    cart: cartSlice.reducer,
    router: routerReducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      routerMiddleware,
      productsApi.middleware,
      categoriesApi.middleware,
      ordersApi.middleware
    ),
});

export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
