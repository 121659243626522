export const getLocalStorageItem = (name: string, defaultValue = {}) => {
  try {
    const value = localStorage.getItem(name);

    if (!value) return defaultValue;

    const cart = JSON.parse(value);

    return typeof cart === "object" ? cart : {};
  } catch (error) {
    return defaultValue;
  }
};

export const saveLocalStorageItem = (name: string, state: unknown) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(name, serializedState);
  } catch (e) {
    console.log("Error save local");
  }
};
