import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_TAGS, SERVICE_API_URL } from "settings";
import { IFormOrderState } from "types";

const endpoints = {
  createOrder: "",
};

type TOrderProduct = {
  id: number;
  variantId: number | null;
  count: number;
};

type TOrderBody = {
  products: TOrderProduct[];
  delivery: IFormOrderState;
  idUser: string;
  deliverySum: number;
};
type TOrderResponse = {
  status: string;
  data: {
    order?: number;
  };
};

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVICE_API_URL}orders/`,
  }),
  tagTypes: [API_TAGS.orders],
  endpoints: (builder) => ({
    addOrder: builder.mutation<TOrderResponse, TOrderBody>({
      query: (body) => ({
        url: endpoints.createOrder,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useAddOrderMutation } = ordersApi;
