import { Form, Input } from "components";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PAGES_LINKS } from "settings";
import css from "./ProductSearchForm.module.scss";

const ProductSearchForm: FC = () => {
  const [searchVal, setSearchVal] = useState("");
  const navigate = useNavigate();

  const searchProducts = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate(`${PAGES_LINKS.search}?q=${searchVal}`);
  };

  const changeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(event.target.value);
  };

  return (
    <Form className={css.search} sendForm={searchProducts}>
      <Input
        className={css.search_input}
        type="search"
        name="search"
        placeholder="Искать товар..."
        onChange={changeSearchText}
        value={searchVal}
        isControlled={true}
      />

      <label className={css.search_submit_wrapper}>
        <Input
          type="submit"
          className="hidden_element"
          submitBtnText=""
          aria-label="Искать"
        />
      </label>
    </Form>
  );
};

export default ProductSearchForm;
