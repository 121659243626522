import { FC, ReactNode, useEffect, useRef } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import css from "./PageTransition.module.scss";

type TPageTransitionProps = {
  children: ReactNode;
  route: string;
};

const TRANSITION_CHANGE_PAGE_TIME = 700;

const PageTransition: FC<TPageTransitionProps> = ({ children, route }) => {
  const childRef = useRef<HTMLDivElement>(null);
  const pageYOffset = useRef<number>(0);

  // scroll listener
  useEffect(() => {
    const getScrollSize = () => {
      pageYOffset.current = window.pageYOffset;
    };

    getScrollSize();

    window.addEventListener("scroll", getScrollSize);

    return () => {
      window.removeEventListener("scroll", getScrollSize);
    };
  }, []);

  const exitingFunction = () => {
    document.body.style.setProperty(
      "--top-section",
      `${-1 * pageYOffset.current}px`
    );
  };

  const enteredFunction = () => {
    document.body.style.setProperty("--top-section", "0px");
  };

  return (
    <main className={`main ${css.main}`}>
      <TransitionGroup component={null}>
        <CSSTransition
          key={route}
          timeout={TRANSITION_CHANGE_PAGE_TIME}
          onExit={exitingFunction}
          onEntered={enteredFunction}
          unmountOnExit
          classNames={{
            appear: css.pageAppear,
            appearActive: css.pageAppearActive,
            appearDone: css.pageAppearDone,
            enter: css.pageEnter,
            enterActive: css.pageEnterActive,
            enterDone: css.pageEnterDone,
            exit: css.pageExit,
            exitActive: css.pageExitActive,
            exitDone: css.pageExitDone,
          }}
        >
          <div ref={childRef} className={css.pageContentWrapper}>
            {children}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </main>
  );
};

export default PageTransition;
