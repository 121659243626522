import { useEffect } from "react";
// import { useTelegram } from "hooks/index";
import { saveLocalStorageItem } from "utils";
import { useAppSelector } from "store";
import { SiteRouter } from "./SiteRouter";
// import AppNoContent from "./AppNoContent";
import { LOCALSTORAGE_ITEM_NAME } from "settings";

const App = () => {
  const { cart } = useAppSelector((store) => store.cart);
  // const { userId, telegramReady } = useTelegram();

  useEffect(() => {
    const saveState = () => {
      saveLocalStorageItem(LOCALSTORAGE_ITEM_NAME, cart);
    };

    window.addEventListener("beforeunload", saveState);

    return () => window.removeEventListener("beforeunload", saveState);
  }, [cart]);

  return <SiteRouter />;
  // return <>{telegramReady && userId ? <SiteRouter /> : <AppNoContent />}</>;
};

export default App;
