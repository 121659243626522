import { FC, useEffect } from "react";
import { changeHeaderOptions, useAppDispatch } from "store";
import { CartProductList, Layout, Section, Title } from "components";

import css from "./Cart.module.scss";
import { useCartItems } from "hooks";
import { Link } from "react-router-dom";
import { PAGES_LINKS } from "settings";

const Cart: FC = () => {
  const dispatch = useAppDispatch();
  const { totalSum } = useCartItems();

  useEffect(() => {
    dispatch(
      changeHeaderOptions({
        showHeaderTop: true,
        showBackButton: false,
        showMainPageButton: false,
        showSearch: true,
      })
    );
  }, [dispatch]);

  return (
    <Layout>
      <Section className={css.cart}>
        <Title Tag="h1" className={css.top__title} text="Корзина" />

        <CartProductList />

        {totalSum !== "0.00" && (
          <Link
            to={PAGES_LINKS.order}
            className={"button " + css.order_delivery}
          >
            <span>Оформить заказ</span>
          </Link>
        )}
      </Section>
    </Layout>
  );
};

export default Cart;
