import React, { forwardRef } from "react";
import { TChangeElHandler } from "../../../types";

type TInputProps = {
  type: string;
  name?: string;
  placeholder?: string;
  value?: string;
  id?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: TChangeElHandler<HTMLInputElement>;
  accept?: string;
  submitBtnText?: string;
  isControlled?: boolean;
  tabIndex?: number;
};

const Input = forwardRef<HTMLInputElement, TInputProps>(
  (
    {
      type,
      name,
      placeholder,
      value,
      id,
      required,
      className,
      disabled,
      checked,
      onChange,
      accept,
      submitBtnText,
      isControlled = false,
      tabIndex = 0,
      ...last
    }: TInputProps,
    ref?: React.Ref<HTMLInputElement>
  ) => (
    <input
      type={type}
      name={name}
      placeholder={placeholder}
      value={submitBtnText ? submitBtnText : isControlled ? value : undefined}
      defaultValue={isControlled ? undefined : value}
      id={id}
      required={required}
      className={className}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      ref={ref}
      accept={accept}
      tabIndex={tabIndex}
      {...last}
    />
  )
);

Input.displayName = "Input";

export default Input;
