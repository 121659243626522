import css from "./Spinner.module.scss";

const Spinner = () => (
  <div className={css.spinner}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default Spinner;
