import { FC } from "react";
import classNames from "classnames";
import {
  useAppDispatch,
  useAppSelector,
  decreaseCartElement,
  increaseCartElement,
} from "store";
import Button from "../UI/Button";
import { MAX_PRODUCTS_ITEM_ONE_KIND } from "settings";
import css from "./ProductCountBtns.module.scss";

type TProductCountBtns = {
  idParent: number | string | null;
  id: number | string;
  quantity: number | null;
  className?: string;
  disabled?: boolean;
};

const ProductCountBtns: FC<TProductCountBtns> = ({
  idParent = null,
  id,
  className = "",
  quantity,
  disabled = false,
}) => {
  const { cart } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();

  const idItem = idParent ? idParent + "-" + id : id?.toString();
  const productInCart = idItem in cart;

  const minusOneNumberProduct = () => {
    dispatch(decreaseCartElement(idItem));
  };

  const plusOneNumberProduct = () => {
    dispatch(increaseCartElement(idItem));
  };

  const isMaxCountProductItem = cart[idItem]
    ? cart[idItem] === MAX_PRODUCTS_ITEM_ONE_KIND || cart[idItem] === quantity
    : false;

  return (
    <>
      {productInCart ? (
        <div className={classNames(css.item__count_values, className)}>
          <Button
          disabled={disabled}
            className={css.item__count_minus}
            title="уменьшить количество"
            onClick={minusOneNumberProduct}
          >
            -
          </Button>

          <p className={css.item__count_value}>{cart[idItem]}</p>

          <Button
            className={css.item__count_plus}
            title="увеличить количество"
            onClick={plusOneNumberProduct}
            disabled={disabled || isMaxCountProductItem}
          >
            +
          </Button>
        </div>
      ) : (
        <Button
          className={classNames("button", css.product__button)}
          onClick={plusOneNumberProduct}
        >
          В корзину
        </Button>
      )}
    </>
  );
};

export default ProductCountBtns;
